import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import Api from "../../apis/Api";
import { UsersTable } from "./UsersTable";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setSelUser } from "../../state/rootSlice";
import { DropDownLimitReloader } from "../favorites/index";

export default function Users() {
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const limit = useSelector(state => state.root.limit);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const history = useHistory();

  React.useEffect(() => {
    loadData();
  }, [page, limit]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res = await Api.userList(page, limit, search);
      setLoading(false);
      if (res.status == 200 && res.data.status == 200) {
        setRows(res.data.data);
        setTotalRows(res.data.total);
      } else {
        setRows([]);
        alert("Error while loading : " + res.error);
      }
    } catch (ex) {
      setLoading(false);
      alert(ex.message);
    }
  };

  const onSearch = async ()=>{
    await loadData()
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Users</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Users</h4>
          <p className="mb-0">Users list in Tribeone platform.</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0 item-hidden">
          {/* <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>

            <InputGroup className="mb-3">
              <Form.Control
                type="text" placeholder="Search"
                value={search}
                onChange={e => { setSearch(e.target.value) }}
              />
              <Button variant="outline-primary" id="button-addon2" onClick={onSearch}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>

            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <DropDownLimitReloader
              loading={loading}
              loadData={loadData}
            />
          </Col>
        </Row>
      </div>

      <UsersTable
        rows={rows}
        total={totalRows}
        page={page}
        limit={limit}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        onDetails={(user) => {
          dispatch(setSelUser(user));
          history.push(Routes.UsersEdit.path);
        }}
        onEdit={(user) => {
          dispatch(setSelUser(user));

          history.push(Routes.UsersEdit.path);
        }}
        onRemove={(user) => { }}
      />
    </>
  );
}
