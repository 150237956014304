import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faAngleUp,
    faArrowDown,
    faArrowUp,
    faEdit,
    faEllipsisH,
    faExternalLinkAlt,
    faEye,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
    Col,
    Row,
    Nav,
    Card,
    Image,
    Button,
    Table,
    ProgressBar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import commands from "../data/commands";
import { Shorter } from "../features/loans/LoanTable";
import moment from "moment";
import { PaginationFooter } from "../features/favorites/FavTable";
import { HOST } from "../apis/Api";

const ValueChange = ({ value, suffix }) => {
    const valueIcon = value < 0 ? faAngleDown : faAngleUp;
    const valueTxtColor = value < 0 ? "text-danger" : "text-success";

    return value ? (
        <span className={valueTxtColor}>
            <FontAwesomeIcon icon={valueIcon} />
            <span className="fw-bold ms-1">
                {Math.abs(value)}
                {suffix}
            </span>
        </span>
    ) : (
        "--"
    );
};

export const PageVisitsTable = () => {
    const TableRow = (props) => {
        const { pageName, views, returnValue, bounceRate } = props;
        const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
        const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

        return (
            <tr>
                <th scope="row">{pageName}</th>
                <td>{views}</td>
                <td>${returnValue}</td>
                <td>
                    <FontAwesomeIcon
                        icon={bounceIcon}
                        className={`${bounceTxtColor} me-3`}
                    />
                    {Math.abs(bounceRate)}%
                </td>
            </tr>
        );
    };

    return (
        <Card border="light" className="shadow-sm">
            <Card.Header>
                <Row className="align-items-center">
                    <Col>
                        <h5>Page visits</h5>
                    </Col>
                    <Col className="text-end">
                        <Button variant="secondary" size="sm">
                            See all
                        </Button>
                    </Col>
                </Row>
            </Card.Header>
            <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">Page name</th>
                        <th scope="col">Page Views</th>
                        <th scope="col">Page Value</th>
                        <th scope="col">Bounce rate</th>
                    </tr>
                </thead>
                <tbody>
                    {pageVisits.map((pv) => (
                        <TableRow key={`page-visit-${pv.id}`} {...pv} />
                    ))}
                </tbody>
            </Table>
        </Card>
    );
};

export const PageTrafficTable = () => {
    const TableRow = (props) => {
        const {
            id,
            source,
            sourceIcon,
            sourceIconColor,
            sourceType,
            category,
            rank,
            trafficShare,
            change,
        } = props;

        return (
            <tr>
                <td>
                    <Card.Link href="#" className="text-primary fw-bold">
                        {id}
                    </Card.Link>
                </td>
                <td className="fw-bold">
                    <FontAwesomeIcon
                        icon={sourceIcon}
                        className={`icon icon-xs text-${sourceIconColor} w-30`}
                    />
                    {source}
                </td>
                <td>{sourceType}</td>
                <td>{category ? category : "--"}</td>
                <td>{rank ? rank : "--"}</td>
                <td>
                    <Row className="d-flex align-items-center">
                        <Col xs={12} xl={2} className="px-0">
                            <small className="fw-bold">{trafficShare}%</small>
                        </Col>
                        <Col xs={12} xl={10} className="px-0 px-xl-1">
                            <ProgressBar
                                variant="primary"
                                className="progress-lg mb-0"
                                now={trafficShare}
                                min={0}
                                max={100}
                            />
                        </Col>
                    </Row>
                </td>
                <td>
                    <ValueChange value={change} suffix="%" />
                </td>
            </tr>
        );
    };

    return (
        <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
                <Table
                    responsive
                    className="table-centered table-nowrap rounded mb-0"
                >
                    <thead className="thead-light">
                        <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">Traffic Source</th>
                            <th className="border-0">Source Type</th>
                            <th className="border-0">Category</th>
                            <th className="border-0">Global Rank</th>
                            <th className="border-0">Traffic Share</th>
                            <th className="border-0">Change</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageTraffic.map((pt) => (
                            <TableRow key={`page-traffic-${pt.id}`} {...pt} />
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export const RankingTable = () => {
    const TableRow = (props) => {
        const {
            country,
            countryImage,
            overallRank,
            overallRankChange,
            travelRank,
            travelRankChange,
            widgetsRank,
            widgetsRankChange,
        } = props;

        return (
            <tr>
                <td className="border-0">
                    <Card.Link href="#" className="d-flex align-items-center">
                        <Image
                            src={countryImage}
                            className="image-small rounded-circle me-2"
                        />
                        <div>
                            <span className="h6">{country}</span>
                        </div>
                    </Card.Link>
                </td>
                <td className="fw-bold border-0">
                    {overallRank ? overallRank : "-"}
                </td>
                <td className="border-0">
                    <ValueChange value={overallRankChange} />
                </td>
                <td className="fw-bold border-0">
                    {travelRank ? travelRank : "-"}
                </td>
                <td className="border-0">
                    <ValueChange value={travelRankChange} />
                </td>
                <td className="fw-bold border-0">
                    {widgetsRank ? widgetsRank : "-"}
                </td>
                <td className="border-0">
                    <ValueChange value={widgetsRankChange} />
                </td>
            </tr>
        );
    };

    return (
        <Card border="light" className="shadow-sm">
            <Card.Body className="pb-0">
                <Table
                    responsive
                    className="table-centered table-nowrap rounded mb-0"
                >
                    <thead className="thead-light">
                        <tr>
                            <th className="border-0">Country</th>
                            <th className="border-0">All</th>
                            <th className="border-0">All Change</th>
                            <th className="border-0">Travel & Local</th>
                            <th className="border-0">Travel & Local Change</th>
                            <th className="border-0">Widgets</th>
                            <th className="border-0">Widgets Change</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageRanking.map((r) => (
                            <TableRow key={`ranking-${r.id}`} {...r} />
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export const TransactionsTable = ({
    rows = [],
    total,
    page = 1,
    limit = 10,
    loan,
    onChangePage,
    approve,
    relay,
    onDetails,
    onEdit,
    onRemove,
}) => {
    const totalTransactions = total;

    const TableRow = (props) => {
        const {
            rowIndex,
            id,
            trx_hash,
            usd_price,
            eth_price,
            from,
            to,
            index_repayment,
            nft_token_id,
            nft_token_address,
            haka_balance,
            haka_added,
            market_place,
            trx_type,
            net_mode,
            created_at,
            updated_at,
            loan,
        } = props;

        return (
            <tr>
                <td>
                    <Card.Link as={Link} className="fw-normal">
                        {rowIndex}
                    </Card.Link>
                </td>
                <td>
                    <span className="fw-normal">
                        {loan && loan.user ? loan.user.username : "Unknown"}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">{index_repayment}</span>
                </td>
                <td>
                    <span className="fw-normal">
                        <Shorter
                            val={trx_hash}
                            center={true}
                            len={12}
                            isLoanTest={net_mode == "test"}
                        />
                    </span>
                </td>
                <td>
                    <span className="fw-normal">${usd_price}</span>
                </td>
                <td title={eth_price}>
                    <span className="fw-normal">
                        {eth_price
                            ? `ETH ${new BigNumber(eth_price)
                                  .dividedBy(10 ** 18)
                                  .toFixed(6)}`
                            : ""}
                    </span>
                </td>
                <td>
                    <span className={`fw-normal`}>
                        <Shorter
                            val={from}
                            center={true}
                            len={12}
                            isLoanTest={net_mode == "test"}
                        />
                    </span>
                </td>
                <td>
                    <span className={`fw-normal`}>
                        <Shorter
                            val={to}
                            center={true}
                            len={12}
                            isLoanTest={net_mode == "test"}
                        />
                    </span>
                </td>
                <td>
                    <span className={`fw-normal`}>
                        <Shorter
                            val={nft_token_id}
                            center={true}
                            len={12}
                            isLoanTest={net_mode == "test"}
                        />
                    </span>
                </td>
                <td>
                    <span className={`fw-normal`}>
                        <Shorter
                            val={nft_token_address}
                            center={true}
                            len={12}
                            isLoanTest={net_mode == "test"}
                        />
                    </span>
                </td>
                <td>
                    <span className="fw-normal">{haka_balance}</span>
                </td>
                <td>
                    <span className="fw-normal">{haka_added}</span>
                </td>
                <td>
                    <span className="fw-normal">{market_place}</span>
                </td>
                <td>
                    <span className="fw-normal">{trx_type}</span>
                </td>
                <td>
                    <span className="fw-normal">
                        {moment(created_at).format("YYYY-MM-DDTHH:mm:ss")}
                    </span>
                </td>
            </tr>
        );
    };

    const totalPages = Math.ceil(total / limit);
    const numberOfRows = rows ? rows.length : 0;

    return (
        <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
        >
            <Card.Body className="pt-0">
                <Table hover className="user-table align-items-center">
                    <thead>
                        <tr>
                            <th className="border-bottom">Sr.No</th>
                            <th className="border-bottom">User</th>
                            <th className="border-bottom">PayIndex</th>
                            <th className="border-bottom">Trx Hash</th>
                            <th className="border-bottom">Price(USD)</th>
                            <th className="border-bottom">Price(ETH)</th>
                            <th className="border-bottom">From</th>
                            <th className="border-bottom">To</th>
                            <th className="border-bottom">NFT TokenID</th>
                            <th className="border-bottom">NFT Asset Addr</th>
                            <th className="border-bottom">HAKA Balance</th>
                            <th className="border-bottom">HAKA Added</th>
                            <th className="border-bottom">Market</th>
                            <th className="border-bottom">Trx Type</th>
                            <th className="border-bottom">Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((t, rowIndex) => (
                            <TableRow
                                key={`transaction-${t.id}`}
                                {...t}
                                loan={loan ?? t.loan}
                                rowIndex={rowIndex + 1}
                            />
                        ))}
                    </tbody>
                </Table>
                <PaginationFooter
                    page={page}
                    onChangePage={onChangePage}
                    totalPages={totalPages}
                    numberOfRows={numberOfRows}
                    total={total}
                />
            </Card.Body>
        </Card>
    );
};

export const LogsTable = ({
    rows = [],
    total,
    page = 1,
    limit = 10,
    onChangePage,
    onDetails,
    onEdit,
    onRemove,
}) => {
    const totalLogs = total;

    const TableRow = (props) => {
        const { rowIndex, row } = props;

        return (
            <tr>
                <td>{rowIndex}</td>
                <td>
                    <span className="fw-normal">{row}</span>
                </td>
                <td>
                    <span className="fw-normal">
                        <a
                            href={`${HOST}log/download/${row}`}
                            target={"_blank"}
                        >
                            <Button variant="outline-info" className="m-1" size="sm">
                                Download
                            </Button>
                        </a>
                    </span>
                </td>
            </tr>
        );
    };

    const totalPages = Math.ceil(total / limit);
    const numberOfRows = rows ? rows.length : 0;

    return (
        <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
        >
            <Card.Body className="pt-0">
                <Table hover className="user-table align-items-center">
                    <thead>
                        <tr>
                            <th className="border-bottom">No</th>
                            <th className="border-bottom">Log File Name</th>
                            <th className="border-bottom">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((t, rowIndex) => (
                            <TableRow
                                key={`log-${rowIndex}`}
                                row={t}
                                rowIndex={rowIndex + 1}
                            />
                        ))}
                    </tbody>
                </Table>
                <PaginationFooter
                    page={page}
                    onChangePage={onChangePage}
                    totalPages={totalPages}
                    numberOfRows={numberOfRows}
                    total={total}
                />
            </Card.Body>
        </Card>
    );
};

export const CommandsTable = () => {
    const TableRow = (props) => {
        const { name, usage = [], description, link } = props;

        return (
            <tr>
                <td className="border-0" style={{ width: "5%" }}>
                    <code>{name}</code>
                </td>
                <td className="fw-bold border-0" style={{ width: "5%" }}>
                    <ul className="ps-0">
                        {usage.map((u) => (
                            <ol key={u} className="ps-0">
                                <code>{u}</code>
                            </ol>
                        ))}
                    </ul>
                </td>
                <td className="border-0" style={{ width: "50%" }}>
                    <pre className="m-0 p-0">{description}</pre>
                </td>
                <td className="border-0" style={{ width: "40%" }}>
                    <pre>
                        <Card.Link href={link} target="_blank">
                            Read More{" "}
                            <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                className="ms-1"
                            />
                        </Card.Link>
                    </pre>
                </td>
            </tr>
        );
    };

    return (
        <Card border="light" className="shadow-sm">
            <Card.Body className="p-0">
                <Table
                    responsive
                    className="table-centered rounded"
                    style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                >
                    <thead className="thead-light">
                        <tr>
                            <th className="border-0" style={{ width: "5%" }}>
                                Name
                            </th>
                            <th className="border-0" style={{ width: "5%" }}>
                                Usage
                            </th>
                            <th className="border-0" style={{ width: "50%" }}>
                                Description
                            </th>
                            <th className="border-0" style={{ width: "40%" }}>
                                Extra
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {commands.map((c) => (
                            <TableRow key={`command-${c.id}`} {...c} />
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};
