import React from "react";
import moment from "moment-timezone";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Table,
} from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Api from "../../apis/Api";
import { useSnackbar } from "notistack";
import { Shorter } from "./LoanTable";
import { TransactionsTable } from "../../components/Tables";
import { Nav, Tab } from "@themesberg/react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import ColorLoader from "../../assets/img/color-loader.gif";
import LoanSellModal from "./LoanSellModal";
import LoanApproveResultView from "./LoanApproveResultView";
import LoanRelayResultView from "./LoanRelayResultView";
import {
  LoanStatusInContract,
  useContract,
} from "../../context/ContractContext";
import { Utils } from "../../utils/utils";
import { TRIBEONE_ADDRESS } from "../../config/TribeOne";
import LoanStep from "./loan_step";
import { IsManaContract } from "../../config/ManaETH";

const BigNumber = require("bignumber.js");

export const LoanStatus = {
  AVOID_ZERO: "AVOID_ZERO",
  LISTED: "LISTED",
  APPROVED: "APPROVED",
  LOANACTIVED: "LOANACTIVED",
  LOANPAID: "LOANPAID",
  WITHDRAWN: "WITHDRAWN",
  FAILED: "FAILED",
  CANCELLED: "CANCELLED",
  DEFAULTED: "DEFAULTED",
  LIQUIDATION: "LIQUIDATION",
  POSTLIQUIDATION: "POSTLIQUIDATION",
  RESTWITHDRAWN: "RESTWITHDRAWN",
  RESTLOCKED: "RESTLOCKED",
  REJECTED: "REJECTED",
};

export const LoanInfoForm = ({ onReloadCallback }) => {
  const { token, address } = useAuth();
  const {
    isAdmin,
    approveLoan,
    getLoanIdFromCreateTransaction,
    relayNFT,
    getLoans,
    checkNFTOwner,
    setApprovalForAll,
    checkLoanSettableToDefaulted,
    checkLoanSettableToLiquidation,
    setLoanDefaulted,
    setLoanLiquidation,
    isApprovedForAll,
  } = useContract();

  const curLoan = useSelector((state) => state.root.curLoan);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [showSellModal, setShowSellModal] = React.useState(false);
  const [isOwner, setIsOwner] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      if (curLoan && address) {
        console.log("curLoan: ", curLoan);
        console.log(
          "begin getExpectedLastPaymentTimeLoan",
          curLoan.contract_loan_id
        );
        const checkRes = await checkNFTOwner(
          curLoan.asset_contract_address,
          curLoan.schema,
          curLoan.token_id,
          address
        );
        setIsOwner(!!checkRes);
      }
    })();
  }, [curLoan, address]);

  const handleLiquidation = async (amount) => {
    if (!token) {
      return;
    }
    if (curLoan.market_place == "opensea") {
      //* create sell order
      const data = {
        tokenId: curLoan.token_id,
        tokenContractAddress: curLoan.asset_contract_address,
        startAmount: amount,
        schemaName: curLoan.nft.asset_contract_schema_name,
        buyerAgentWallet: curLoan.buyer_agent_wallet_address,
        token: token,
        loanId: curLoan.id,
      };
      setLoading(true);
      const sellRes = await Api.createSellOrder(data);
      setLoading(false);
      if (sellRes.status == 200) {
        enqueueSnackbar("Successfully created sell order.", {
          variant: "success",
        });
        if (onReloadCallback) {
          onReloadCallback();
        }
      } else {
        enqueueSnackbar(sellRes.error, { variant: "error" });
        if (onReloadCallback) {
          onReloadCallback();
        }
      }
    } else {
      //* liquidation call from smart contract
    }
  };

  const handleCancelRequest = async () => {
    //* it will send cancel request email to user, then user will cancel loan. admin can not cancel request directly.

    if (!curLoan) {
      enqueueSnackbar("Loan is not selected, please try again.", {
        variant: "error",
      });
      return;
    }

    try {
      setLoading(true);

      // const loanDetail = await getLoans(curLoan.contract_loan_id);

      // const msgValue = new BigNumber(loanDetail.loanAsset.amount.toString()).plus(new BigNumber(loanDetail.fundAmount.toString())).toString();

      // const receipt = await relayNFT(
      //   curLoan.contract_loan_id,
      //   address,
      //   false,
      //   msgValue,
      //   address,
      //   (txHash, _loanId) => { },
      // );

      // const hash = receipt.transactionHash;

      // enqueueSnackbar("Loan Failed!", { variant: "success" });
      const res = await Api.adminCancelRequest(curLoan.contract_loan_id, token);
      console.log("adminCancelRequest: Result:>> ", { res });
      enqueueSnackbar("Cancel request sent via email to user", {
        variant: "success",
      });
    } catch (ex) {
      console.log("Exception at send cancel request : ", ex);
      enqueueSnackbar(
        "Failed to send cancel request to user, please check details.",
        { variant: "error" }
      );
    }

    if (onReloadCallback) {
      onReloadCallback();
    }

    setLoading(false);
  };

  const checkLoanWallet = async () => {
    if (!curLoan) {
      enqueueSnackbar("Loan is not selected, please try again.", {
        variant: "error",
      });
      return false;
    }

    if (!address) {
      enqueueSnackbar(
        "Wallet is not connected, please try to connect wallet first.",
        {
          variant: "error",
        }
      );
      return false;
    }

    const _isAdmin = await isAdmin(address);
    if (_isAdmin !== true) {
      enqueueSnackbar(
        "Permission is not right for approve, please try to connect other wallet.",
        {
          variant: "error",
        }
      );
      return false;
    }

    return true;
  };

  const handleRelay = async () => {
    console.log("handleRelay called " + Date.now());
    const isCheckLoanWallet = await checkLoanWallet();
    if (!isCheckLoanWallet) {
      return;
    }

    setLoading(true);

    let accepted;
    //* relayNFT for accepted = true

    const checkRes = await checkNFTOwner(
      curLoan.asset_contract_address,
      curLoan.schema,
      curLoan.token_id,
      address
    );

    if (!checkRes) {
      enqueueSnackbar(
        "You are not owner of this NFT, please try to buy NFT at Opensea platform first.",
        { variant: "error" }
      );
      setLoading(false);
      return;
    }
    console.log("checkNFTOwner : ", checkRes);

    if (checkRes == true) {
      accepted = true;
    } else {
      if (
        window.confirm(
          `You didn't purchase nft for loan. Loan is set to fail. Are you sure you want to set the loan failed?`
        )
      ) {
        accepted = false;
      } else {
        setLoading(false);
        return;
      }
    }

    let approvedForAll = await isApprovedForAll(
      curLoan.schema,
      curLoan.asset_contract_address,
      address,
      TRIBEONE_ADDRESS
    );

    if (approvedForAll == false) {
      const approvalRes = await setApprovalForAll(
        curLoan.asset_contract_address,
        curLoan.schema,
        TRIBEONE_ADDRESS,
        address,
        (hash) => {}
      );

      console.log(Date.now() + " - approvalRes : ", approvalRes);

      if (!approvalRes) {
        if (
          window.confirm(
            `NFT contract is not valid to approval For All, Are you sure you want to set the loan failed?`
          )
        ) {
          accepted = false;
        } else {
          setLoading(true);
          return;
        }
      }
    }

    try {
      const loanDetail = await getLoans(curLoan.contract_loan_id);
      console.log("loanDetails :", loanDetail);

      const msgValue = loanDetail.collateralAsset.amount;

      const receipt = await relayNFT(
        curLoan.contract_loan_id,
        address,
        accepted,
        msgValue,
        address,
        (txHash, _loanId) => {}
      );

      const hash = receipt.transactionHash;

      enqueueSnackbar("NFT Relayed!", { variant: "success" });
    } catch (ex) {
      const errorMsg =
        ex && ex.message ? ex.message : "RPC Error: MetaMask Tx Signature";
      enqueueSnackbar(errorMsg, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  const handleApprove = async () => {
    // const isCheckLoanWallet = await checkLoanWallet();
    // if (!isCheckLoanWallet) {
    //   return;
    // }

    const tokenPriceBN = new BigNumber(curLoan.nft_price_eth).multipliedBy(
      10 ** 18
    );
    const tokenPriceStr = tokenPriceBN.toString();

    setLoading(true);

    try {
      let loanId = curLoan.contract_loan_id;
      if (!loanId) {
        loanId = await getLoanIdFromCreateTransaction(
          curLoan.loan_create_trx_hash
        );
        console.log("LoanId: ", loanId);
      }

      //TODO checking Loan is stored in backend with LISTED status.
      //* If not it is needed to create loan approve data manually and run contract function.

      const receipt = await approveLoan(
        loanId,
        tokenPriceStr,
        address,
        address,
        (txHash, _loanId) => {}
      );

      const hash = receipt.transactionHash;

      enqueueSnackbar("Loan approved!", { variant: "success" });
      if (onReloadCallback) {
        onReloadCallback();
      }
    } catch (ex) {
      console.log("Exception at approveLoan: ", ex);
      const msg =
        ex && ex.message
          ? ex.message
          : "Failed to approve please check console.";
      enqueueSnackbar(msg, { variant: "error" });
    }

    setLoading(false);
  };

  const gotoOpenseaNFTPage = () => {
    window
      .open(
        Utils.getOpenseaNFTUrl(
          curLoan.token_id,
          curLoan.asset_contract_address
        ),
        "_blank"
      )
      .focus();
  };

  const onClose = () => {
    history.go(-1);
  };

  const getEMI = (curLoan) => {
    const resEth = new BigNumber(curLoan.loan_amount_eth)
      .multipliedBy(curLoan.ltv / (100 - curLoan.ltv))
      .multipliedBy(1 + curLoan.interest_rate / 100)
      .dividedBy(curLoan.loan_duration);

    const resUsd =
      (curLoan.loan_amount_usd *
        (curLoan.ltv / (100 - curLoan.ltv)) *
        (1 + curLoan.interest_rate / 100)) /
      curLoan.loan_duration;

    return {
      emiEth: resEth.dividedBy(10 ** 18).toFixed(6),
      emiUSD: new BigNumber(resUsd).toFixed(6),
    };
  };

  const onSetLoanDefaulted = async () => {
    setLoading(true);

    try {
      if (await checkLoanSettableToDefaulted(curLoan.contract_loan_id)) {
        const res = await setLoanDefaulted({
          loanId: curLoan.contract_loan_id,
          from: address,
          transactionHashCallback: (hash, loanId) => {},
        });
      } else {
        enqueueSnackbar("This loan can not be set as defauled", {
          variant: "error",
        });
      }
    } catch (ex) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }

    setLoading(false);
  };

  const onSetLoanLiquidate = async () => {
    setLoading(true);

    try {
      const resConf = await Api.getConf(token);
      if (!resConf || !resConf.data || !resConf.data.data) {
        enqueueSnackbar("Failed to get config data for grace period.", {
          variant: "error",
        });
        return;
      }

      const confData = resConf.data.data;
      const GracePeriod = confData.secs_per_day_cron * 14 * 1000;
      if (
        await checkLoanSettableToLiquidation(
          curLoan.contract_loan_id,
          GracePeriod
        )
      ) {
        const res = await setLoanLiquidation({
          loanId: curLoan.contract_loan_id,
          from: address,
          transactionHashCallback: (hash, loanId) => {},
        });
      } else {
        enqueueSnackbar("This loan can not be set as defauled", {
          variant: "error",
        });
      }
    } catch (ex) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }

    setLoading(false);
  };

  const cancelBtnDisable = curLoan.status !== "LISTED";

  const approveBtnDisable =
    curLoan.status !== "LISTED" || !curLoan.contract_loan_id;
  const relayBtnDisable = curLoan.status !== "APPROVED";

  const sellOrderBtnDisable = curLoan.status !== "DEFAULTED";

  console.log({curLoan})

  return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <LoanStep curLoan={curLoan} isOwner={isOwner} />

          <Tab.Container defaultActiveKey="nft">
            <Nav fill variant="pills" className="flex-column flex-sm-row">
              <Nav.Item>
                <Nav.Link eventKey="nft" className="mb-sm-3 mb-md-0">
                  NFT
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="loan" className="mb-sm-3 mb-md-0">
                  Loan
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="transactions" className="mb-sm-3 mb-md-0">
                  Transactions
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="internal_trx" className="mb-sm-3 mb-md-0">
                  Internal Trxs
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="nft" className="py-4">
                <Row>
                  <Col md={12}>
                    <Form.Label>User Name</Form.Label>&nbsp;&nbsp;
                    <span>
                      {curLoan && curLoan.user
                        ? curLoan.user.username
                        : "Unknown"}
                    </span>
                  </Col>
                  <Col md={12}>
                    <Form.Label>EMAIL</Form.Label>&nbsp;&nbsp;
                    <span>
                      {curLoan && curLoan.user ? curLoan.user.email : "Unknown"}
                    </span>
                  </Col>
                  <Col md={12}>
                    <Form.Label>Sent Cancel Request: </Form.Label>&nbsp;&nbsp;
                    <span>
                      {curLoan.admin_cancel_requested == false &&
                        curLoan.admin_cancel_requested
                          ?.toString()
                          .toUpperCase()}{" "}
                      {curLoan.admin_cancel_requested_timestamp > 0
                        ? `   at ${new Date(
                            parseInt(curLoan.admin_cancel_requested_timestamp)
                          ).toLocaleString()}`
                        : ""}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} className="mb-4">
                    {
                      curLoan?.token_media_type == 'image' ? 
                      <img
                        className="rounded"
                        src={
                          curLoan?.token_img
                        }
                        alt="token image"
                      /> :
                      <video
                        className="w-100 rounded"
                        src={curLoan?.token_img}
                        alt=""
                        autoPlay
                        controls
                        loop
                        playsInline
                        // muted
                      />
                    }
                   
                  </Col>
                  <Col md={9} className="mb-4">
                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <Form.Label>TokenID</Form.Label>
                        </Col>
                        <Col md={8} className="text-info">
                          <Shorter
                            val={curLoan.token_id}
                            len={12}
                            isLoanTest={curLoan.net_mode == "test"}
                            disableLink={true}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <Form.Label>NFT Token Address</Form.Label>
                        </Col>
                        <Col md={8} className="text-info">
                          <Shorter
                            val={curLoan.asset_contract_address}
                            center={true}
                            isLoanTest={curLoan.net_mode == "test"}
                            len={12}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <Form.Label>NFT Price</Form.Label>
                        </Col>
                        <Col md={8}>
                          <div>
                            {new BigNumber(curLoan.nft_price_eth)
                              .toPrecision(5)
                              .toString()}{" "}
                            ETH
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row>
                        <Col md={4}>
                          <Form.Label>Schema</Form.Label>
                        </Col>
                        <Col md={8}>
                          <div>
                            {curLoan.nft
                              ? curLoan.nft.asset_contract_schema_name
                              : ""}
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      <Row>
                        <Col md={12} className="mt-2">
                          <a
                            href={Utils.getOpenseaNFTUrl(
                              curLoan.token_id,
                              curLoan.asset_contract_address
                            )}
                            target={"_blank"}
                            style={{
                              color: "blue",
                              textDecorationLine: "underline",
                            }}
                          >
                            Go to{" "}
                            {IsManaContract(curLoan.asset_contract_address)
                              ? "Decentraland"
                              : "Opensea"}{" "}
                            to check NFT Order
                          </a>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="loan" className="py-4">
                <Row>
                  <Col md={12} className="mb-4">
                    <Form.Label>Status:</Form.Label>
                    <span>{curLoan.status}</span>
                  </Col>
                  <Col md={6}>
                    <Table>
                      <tbody>
                        <tr>
                          <th>Contract LoanId# : </th>
                          <td>{curLoan.contract_loan_id}</td>
                        </tr>
                        <tr>
                          <th>Fund Amount</th>
                          <td>
                            {BigNumber(curLoan.loan_amount_eth)
                              .dividedBy(10 ** 18)
                              .toPrecision(5)}{" "}
                            ETH
                            {/* <br />${curLoan.loan_amount_usd} */}
                          </td>
                        </tr>
                        <tr>
                          <th>Interest Rate</th>
                          <td>{curLoan.interest_rate}%</td>
                        </tr>
                        <tr>
                          <th>Loan Create Trx Hash</th>
                          <td>{curLoan.loan_create_trx_hash}%</td>
                        </tr>
                        <tr>
                          <th>LTV</th>
                          <td>{curLoan.ltv}%</td>
                        </tr>
                        <tr>
                          <th>Loan Duration</th>
                          <td>{curLoan.loan_duration} months</td>
                        </tr>
                        <tr>
                          <th>Payoff Date</th>
                          <td>
                            {curLoan.payoff_date &&
                            parseInt(curLoan.payoff_date) > 0
                              ? moment(parseInt(curLoan.payoff_date)).format(
                                  "MM/DD/yyyy HH:mm:ss"
                                )
                              : "None"}
                          </td>
                        </tr>
                        <tr>
                          <th>Next Pay Date</th>
                          <td>
                            {curLoan.next_pay_date &&
                            parseInt(curLoan.next_pay_date) > 0
                              ? moment(parseInt(curLoan.next_pay_date)).format(
                                  "MM/DD/yyyy HH:mm:ss"
                                )
                              : "None"}
                          </td>
                        </tr>
                        <tr>
                          <th>Monthly Installments</th>
                          <td>
                            {getEMI(curLoan).emiEth} ETH
                            {/* <br />${getEMI(curLoan).emiUSD} */}
                          </td>
                        </tr>
                        <tr>
                          <th>Agent Buy Trx Hash</th>
                          <td>{curLoan.buy_trx_hash}</td>
                        </tr>
                        <tr>
                          <th>User Wallet</th>
                          <td>{curLoan.user_wallet_address}</td>
                        </tr>
                        <tr>
                          <th>Agent Wallet</th>
                          <td>{curLoan.agent_wallet_address}</td>
                        </tr>
                        <tr>
                          <th>Buyer Agent Wallet</th>
                          <td>{curLoan.buyer_agent_wallet_address}</td>
                        </tr>
                        <tr>
                          <th>Market Place</th>
                          <td>
                            {IsManaContract(curLoan.asset_contract_address)
                              ? "Decentraland"
                              : "Opensea"}
                            {/* {curLoan.market_place} */}
                          </td>
                        </tr>
                        <tr>
                          <th>Repay Token</th>
                          <td>{curLoan.repayToken}</td>
                        </tr>
                        <tr>
                          <th>Pay Method</th>
                          <td>{curLoan.pay_method}</td>
                        </tr>
                        <tr>
                          <th>Created At</th>
                          <td>{curLoan.created_at}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="transactions" className="py-4">
                <Row>
                  <Col md={12}>
                    <TransactionsTable
                      rows={curLoan.transactions}
                      total={
                        curLoan.transactions ? curLoan.transactions.length : 0
                      }
                      approve={curLoan.approve}
                      relay={curLoan.relay}
                      loan={curLoan}
                    />
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="internal_trx" className="py-4">
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <div>Approve Result</div>
                        <LoanApproveResultView />
                      </Col>
                      <Col md={6}>
                        <div>Relay Result</div>
                        <LoanRelayResultView />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <Row>
            <Col md={3}>
              <Button className="m-1" onClick={onClose}>
                Close
              </Button>
            </Col>
            <Col md={9} className="text-right">
              {loading ? (
                <img src={ColorLoader} style={{ width: 40, height: 40 }} />
              ) : (
                <>
                  {!approveBtnDisable && (
                    <Button
                      variant="success"
                      className="m-1"
                      onClick={handleApprove}
                      disabled={approveBtnDisable}
                    >
                      Approve
                    </Button>
                  )}
                  {curLoan.status == "BUY_FAILED" && (
                    <Button
                      variant="info"
                      className="m-1"
                      onClick={gotoOpenseaNFTPage}
                    >
                      Buy NFT
                    </Button>
                  )}

                  {!relayBtnDisable && (
                    <Button
                      variant="success"
                      className="m-1"
                      onClick={handleRelay}
                      disabled={relayBtnDisable}
                    >
                      Relay
                    </Button>
                  )}

                  <Button
                    variant="danger"
                    className="m-1"
                    onClick={handleCancelRequest}
                    disabled={cancelBtnDisable}
                  >
                    Cancel Request
                  </Button>
                  {curLoan.status == LoanStatus.LOANACTIVED && (
                    <Button
                      variant="danger"
                      className="m-1"
                      onClick={onSetLoanDefaulted}
                    >
                      Default
                    </Button>
                  )}
                  {curLoan.status == LoanStatus.DEFAULTED && (
                    <Button
                      variant="info"
                      className="m-1"
                      onClick={onSetLoanLiquidate}
                    >
                      Liquidate
                    </Button>
                  )}

                  <Button
                    variant="info"
                    className="m-1"
                    onClick={() => {
                      setShowSellModal(true);
                    }}
                    disabled={sellOrderBtnDisable}
                  >
                    {curLoan.market_place == "opensea"
                      ? "Create Sell Order"
                      : "Liquidation"}
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <LoanSellModal
        show={showSellModal}
        onCreate={(amountEth) => {
          setShowSellModal(false);
          handleLiquidation(amountEth);
        }}
        onClose={() => {
          setShowSellModal(false);
        }}
      />
    </>
  );
};
