import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
// import Transactions from "./Transactions";
// import Settings from "./Settings";
import Settings from '../features/settings/index';
import Mails from '../features/mails/index';


import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./templates/Signin";
import Signup from "./templates/Signup";
import ForgotPassword from "./templates/ForgotPassword";
import ResetPassword from "./templates/ResetPassword";
import Lock from "./templates/Lock";
import NotFoundPage from "./templates/NotFound";
import ServerError from "./templates/ServerError";

// documentation pages
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";

import DocsFolderStructure from "./documentation/DocsFolderStructure";


// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";

import Users from "../features/users";
import Transactions from "../features/transactions";
import UserDetail from "../features/users/UserDetail";
import Loans from "../features/loans";
import LoanDetail from "../features/loans/LoanDetail";
import Favorites from "../features/favorites";
import NFTs from "../features/nfts";
import Survey from "../features/survey";
import { useAuth } from "../context/AuthContext";
import { useHistory } from "react-router";
import { EventsTable } from "../features/events/events_table";
import Events from "../features/events";
import Logs from "../features/log";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { user, token, loading } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (
      rest.path != Routes.Signin.path &&
      rest.path != Routes.Signup.path &&
      !loading
    ) {
      if (!token) {
        history.replace(Routes.Signin.path);
      }
    }
  }, [token, user, loading]);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { token, user, loading , tokenLoaded} = useAuth();
  const history = useHistory();

  useEffect(() => {
    
    if (
      rest.path != Routes.Signin.path &&
      rest.path != Routes.Signup.path &&
      !token &&
      tokenLoaded
    ) {
      history.replace(Routes.Signin.path);
    }
  }, [token, user, tokenLoaded]);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />
          <main className="content">
            <Navbar />
            <Component {...props} />
          </main>
        </>
      )}
    />
  );
};

export default () => {

  console.log('ENV checking : ', {
    REACT_APP_API_HOST: process.env.REACT_APP_API_HOST, 
    REACT_APP_NETWORK: process.env.REACT_APP_NETWORK
  });


  return (
    <Switch>
      <RouteWithSidebar
        exact
        path={Routes.DashboardOverview.path}
        component={DashboardOverview}
      />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader
        exact
        path={Routes.ForgotPassword.path}
        component={ForgotPassword}
      />
      <RouteWithLoader
        exact
        path={Routes.ResetPassword.path}
        component={ResetPassword}
      />
      <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
      <RouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFoundPage}
      />
      <RouteWithLoader
        exact
        path={Routes.ServerError.path}
        component={ServerError}
      />

      {/* pages */}
      <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
      <RouteWithSidebar
        exact
        path={Routes.Transactions.path}
        component={Transactions}
      />
      <RouteWithSidebar
        exact
        path={Routes.events.path}
        component={Events}
      />
      <RouteWithSidebar
        exact
        path={Routes.Logs.path}
        component={Logs}
      />
      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
      />
      <RouteWithSidebar
        exact
        path={Routes.Mails.path}
        component={Mails}
      />
      <RouteWithSidebar
        exact
        path={Routes.BootstrapTables.path}
        component={BootstrapTables}
      />

      {/* features */}
      <RouteWithSidebar exact path={Routes.Users.path} component={Users} />

      <RouteWithSidebar
        exact
        path={Routes.UsersEdit.path}
        component={UserDetail}
      />
      <RouteWithSidebar exact path={Routes.Loans.path} component={Loans} />
      <RouteWithSidebar
        exact
        path={Routes.LoansDetail.path}
        component={LoanDetail}
      />
      <RouteWithSidebar
        exact
        path={Routes.Favorites.path}
        component={Favorites}
      />
      {/* <RouteWithSidebar exact path={Routes.Survey.path} component={Survey} /> */}
      <RouteWithSidebar exact path={Routes.NFT.path} component={NFTs} />

      {/* components */}
      <RouteWithSidebar
        exact
        path={Routes.Accordions.path}
        component={Accordion}
      />
      <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
      <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
      <RouteWithSidebar
        exact
        path={Routes.Breadcrumbs.path}
        component={Breadcrumbs}
      />
      <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
      <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
      <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
      <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
      <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
      <RouteWithSidebar
        exact
        path={Routes.Pagination.path}
        component={Pagination}
      />
      <RouteWithSidebar
        exact
        path={Routes.Popovers.path}
        component={Popovers}
      />
      <RouteWithSidebar
        exact
        path={Routes.Progress.path}
        component={Progress}
      />
      <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
      <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
      <RouteWithSidebar
        exact
        path={Routes.Tooltips.path}
        component={Tooltips}
      />
      <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

      <RouteWithSidebar
        exact
        path={Routes.DocsDownload.path}
        component={DocsDownload}
      />
      <RouteWithSidebar
        exact
        path={Routes.DocsQuickStart.path}
        component={DocsQuickStart}
      />
     
      <RouteWithSidebar
        exact
        path={Routes.DocsFolderStructure.path}
        component={DocsFolderStructure}
      />
      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};
