import { LoanStatus } from "./LoanInfoForm";


export default function getLoanSteps({ curLoan }) {
    let res = [];

    if(!curLoan){
      return res;
    }
    
    if (curLoan.status == LoanStatus.AVOID_ZERO) {
      res = [];
    } else if (curLoan.status == LoanStatus.LISTED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVE",
          color: 'gray',
          next_action: true
        },
      ];
    } else if (curLoan.status == LoanStatus.APPROVED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "Buy&Relay",
          color: 'gray',
          next_action: true
        },
      ];
    } else if (curLoan.status == LoanStatus.DEFAULTED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
        {
          label: "DEFAULTED",
          color: 'red',
        },
        {
          label: "LIQUIDATE",
          color: 'gray',
          next_action: true
        },
      ];
    } else if (curLoan.status == LoanStatus.LOANACTIVED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
      ];
    } else if (curLoan.status == LoanStatus.LOANPAID) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
        {
          label: "LOANPAID",
          color: 'green',
        },
      ];
    } else if (curLoan.status == LoanStatus.FAILED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "FAILED",
          color: 'red',
        },
      ];
    } else if (curLoan.status == LoanStatus.CANCELLED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "CANCELLED",
          color: 'red',
        },
      ];
    } else if (curLoan.status == LoanStatus.LIQUIDATION) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
        {
          label: "DEFAULTED",
          color: 'blue',
        },
        {
          label: "LIQUIDATION",
          color: 'blue',
        },
        {
          label: "POSTLIQUIDATE",
          color: 'gray',
          next_action: true
        },
      ];
    } else if (curLoan.status == LoanStatus.POSTLIQUIDATION) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
        {
          label: "DEFAULTED",
          color: 'blue',
        },
        {
          label: "LIQUIDATION",
          color: 'blue',
        },
        {
          label: "POSTLIQUIDATION",
          color: 'blue',
        },
        {
          label: "RESTWITHDRAW",
          color: 'gray',
          next_action: true
        },
        {
          label: "RESTLOCKED",
          color: 'gray',
          next_action: true
        },
      ];
    } else if (curLoan.status == LoanStatus.WITHDRAWN) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
        {
          label: "LOANPAID",
          color: 'green',
        },
        {
          label: "WITHDRAWN",
          color: 'green',
        },
      ];
    } else if (curLoan.status == LoanStatus.RESTWITHDRAWN) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
        {
          label: "DEFAULTED",
          color: 'blue',
        },
        {
          label: "LIQUIDATION",
          color: 'blue',
        },
        {
          label: "POSTLIQUIDATION",
          color: 'blue',
        },
        {
          label: "RESTWITHDRAWN",
          color: 'blue',
        },
      ];
    } else if (curLoan.status == LoanStatus.RESTLOCKED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "APPROVED",
          color: 'green',
        },
        {
          label: "LOANACTIVED",
          color: 'green',
        },
        {
          label: "DEFAULTED",
          color: 'blue',
        },
        {
          label: "LIQUIDATION",
          color: 'blue',
        },
        {
          label: "POSTLIQUIDATION",
          color: 'blue',
        },
        {
          label: "RESTLOCKED",
          color: 'blue',
        },
      ];
    } else if (curLoan.status == LoanStatus.REJECTED) {
      res = [
        {
          label: "LISTED",
          color: 'green',
        },
        {
          label: "REJECTED",
          color: 'red',
        },     
      ];
    }

    return res;
  }