import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import {
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import { UserInfoForm } from "./UserInfoForm";
import { Link } from "react-router-dom";
export default function UserDetail({}) {
  
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>

            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>

            <Breadcrumb.Item active>
              <Link to={Routes.Users.path}>Users</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Detail</Breadcrumb.Item>
          </Breadcrumb>
          <h4>User Detail</h4>
        </div>
      </div>

      <UserInfoForm />
    </>
  );
}
