import React from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import { faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoanStatus } from "./LoanInfoForm";
import getLoanSteps from "./get_loan_steps";

const LoanFlowSteps = [
  "Listed",
  "Approved",
  "NFT Purchased",
  "NFT Relayed",
  "Actived",
  "Failed",
];

export default function LoanStep({ curLoan, isOwner }) {
  const loanSteps = getLoanSteps({ curLoan });

  return (
    <Row>
      <Col md={12}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {loanSteps.map((step, index, arr) => {
            return (
              <>
                <div key={'loan_step_' + index} style={{ align: "center" }}>
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      border: `3px solid ${step.color}`,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {!step.next_action && (
                      <FontAwesomeIcon
                        icon={step.color == 'red' ? faCircle : faCheck}
                        style={{
                          color: step.color,
                          fontSize: 20,
                          margin: 7,
                        }}
                      />
                    )}
                  </div>
                  <p
                    style={{
                      fontSize: "0.9em",
                      color: step.color,
                    }}
                  >
                    {step.label}
                  </p>
                </div>
                {index < arr.length - 1 && (
                  <div
                    style={{
                      marginTop: 20,
                      width: "80px",
                      height: 4,
                      backgroundColor: step.color,
                    }}
                  ></div>
                )}
              </>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}
