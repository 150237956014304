import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Modal,
  InputGroup,
} from "@themesberg/react-bootstrap";

export default function LoanSellModal({show, onClose, onCreate }) {
  const [startAmount, setStartAmount] = useState();
  const { enqueueSnackbar } = useSnackbar();
  
  const handleCreate = ()=>{
    if(startAmount == 0){
      enqueueSnackbar('Please input start amount in ETH', {
        variant:'info'
      })
      return 
    }
    if(onCreate){
      onCreate(startAmount);
    }
  } 

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title className="h6">Sell Order of Service</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="start_amount">
                <Form.Label>Start Amount in ETH</Form.Label>
                <Form.Control
                  required
                  type="number"
                  value={startAmount}
                  onChange={(e) => {
                    setStartAmount(e.target.value);
                  }}
                  placeholder="Enter start amount"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>        
        <Button
          variant="link"
          className="text-gray ms-auto"
          onClick={onClose}
        >
          Close
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
