import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faHome,
  faSearch,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import Api from "../../apis/Api";
import { FavTable } from "./FavTable";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../context/AuthContext";
import ColorLoader from "../../assets/img/color-loader.gif";
import { setPageLimit } from "../../state/rootSlice";

export function DropDownLimitReloader({ loading, loadData, ...props }) {
  const dispatch = useDispatch();
  const limit = useSelector((state) => state.root.limit);

  return (
    <div className="d-flex justify-content-end align-items-center">
      {loading ? (
        <img src={ColorLoader} style={{ width: 40, height: 40 }} />
      ) : (
        <div style={{ marginRight: 20, cursor: "pointer" }} onClick={loadData}>
          <FontAwesomeIcon icon={faSyncAlt} size={'lg'}/>
        </div>
      )}
      <Form>
        <Form.Group className="" style={{width: 80}}>
          <Form.Select value={limit} onChange={e=>{dispatch(setPageLimit(e.target.value));}}>
            {
              [5, 10, 20, 30, 50, 100, 200].map(row=>{
                return <option value={row}>{row}</option>
              })
            }
        
          </Form.Select>
        </Form.Group>
      </Form>

    </div>
  );
}

export default function Favorites() {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const limit = useSelector((state) => state.root.limit);

  const [page, setPage] = React.useState(1);
  // const [limit, setLimit] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const history = useHistory();

  React.useEffect(() => {
    if (token) {
      loadData();
    }
  }, [page, limit, token]);

  const loadData = async () => {
    try {
      setLoading(true);

      const res = await Api.favList(page, limit, token, search);
      setLoading(false);

      if (res.status == 200 && res.data.status == 200) {
        setRows(res.data.data);
        setTotalRows(res.data.total);
      } else {
        setRows([]);
        alert("Error while loading : " + res.error);
      }
    } catch (ex) {
      setLoading(false);

      alert(ex.message);
    }
  };

  const removeFav = async (fav) => {
    try {
      if (window.confirm("Are you sure to remove this?")) {
        const res = await Api.removeFav(fav.id);
        loadData();
      }
    } catch (ex) {
      alert(ex.message);
    }
  };

  const onSearch = async ()=>{
    await loadData()
  }
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Favorites</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Favorites</h4>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
           

            <InputGroup className="mb-3">
              <Form.Control
                type="text" placeholder="Search"
                value={search}
                onChange={e => { setSearch(e.target.value) }}
              />
              <Button variant="outline-primary" id="button-addon2" onClick={onSearch}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>

          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <DropDownLimitReloader
              loading={loading}
              loadData={loadData}
            />
          </Col>
        </Row>
      </div>

      <FavTable
        rows={rows}
        total={totalRows}
        page={page}
        limit={limit}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
        onDetails={(loan) => {}}
        onRemove={(fav) => {
          removeFav(fav);
        }}
      />
    </>
  );
}
