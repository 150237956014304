import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Breadcrumb, Alert } from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import Api from "../../apis/Api";
import { LoanInfoForm } from "./LoanInfoForm";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ColorLoader from "../../assets/img/color-loader.gif";
import { setCurLoan } from "../../state/rootSlice";
import { useAuth } from "../../context/AuthContext";
import { Badge } from "@themesberg/react-bootstrap";

export const BadgeClass = {
  FAILED: "danger",
  LISTED: "info",
  APPROVED: "success",
  DEFAULTED: "warning",
  LOANACTIVED: "success",
  CANCELLED: "primary",
  LOANPAID: "success",
  LIQUIDATION: "info",
  POSTLIQUIDATION: "info",
  WITHDRAWN: "secondary",
  ONSALE: "info",
  BUY_FAILED : 'warning'
};

let timer;

export default function LoanDetail() {
  const [loading, setLoading] = React.useState(false);
  const { token } = useAuth();

  const dispatch = useDispatch();
  const curLoan = useSelector((state) => state.root.curLoan);
 
  
  
  const loadData = async () => {

    if (curLoan) {
      setLoading(true);
      const res = await Api.getLoan(curLoan.id, token);

      if (res.status == 200 && res.data.status == 200) {
        const loanData = {
          ...res.data.data,
          approve: res.data.approve,
          relay: res.data.relay
        }
        dispatch(setCurLoan(loanData));
      } else {
        alert(
          "Failed with some errors : " + res.data ? res.data.error : ""
        );
      }
      setLoading(false);
    }
    if(timer){
      clearTimeout(timer);
    }
    timer = setTimeout(async() => {
      await loadData();  
    }, 10000);
  };

  React.useEffect(()=>{
    (async()=>{
      await loadData();

    })()    
    return ()=>{
      if (timer) {
        clearTimeout(timer);
      }
    }

  },[])


  let statusReason = "";
  if (curLoan.status == "FAILED") {
    statusReason = "Failed to buy nft from opensea.";
  } else if (curLoan.status == "DEFAULTED") {
    statusReason = "NFT is defaulted as penalty.";
  } else if (curLoan.status == "CANCELLED") {
    statusReason = "Loan was cancelled by admin.";
  } else if (curLoan.status == "BUY_FAILED") {
    statusReason = curLoan.err_msg;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>

            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>

            <Breadcrumb.Item active>
              <Link to={Routes.Loans.path}>Loans</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Detail</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row className="w-100  my-3">
        <Col md={6}>
          <h4>Loan Detail [#{curLoan.contract_loan_id}]   </h4>
          <span>{curLoan.id}</span>
          <Badge bg={BadgeClass[curLoan.status]} className="badge-lg" style={{marginLeft: 10}}>
            {curLoan.status}
          </Badge>
          {statusReason && (
            <Alert variant={BadgeClass[curLoan.status]} className="mt-2">{statusReason}</Alert>
          )}
        </Col>
        <Col md={6} className="text-end">
          {loading ? (
            <img src={ColorLoader} style={{ width: 40, height: 40 }} />
          ) : (
            <div
              onClick={loadData}
              className="mx-2"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faSyncAlt} />
            </div>
          )}
        </Col>
      </Row>
      <LoanInfoForm onReloadCallback={loadData} />
    </>
  );
}
