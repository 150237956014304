import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faSignOutAlt,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  Row,
  Col,
  Nav,
  Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import NOTIFICATIONS_DATA from "../data/notifications";
import { useAuth } from "../context/AuthContext";
import { Routes } from "../routes";
import { Utils } from "../utils/utils";
import ConnectWalletModal from "./ConnectWalletModal/ConnectWalletModal";


export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const {
    address
  } = useAuth();

  const { signOut } = useAuth();
  const [showDisCon, setShowDisCon] = React.useState(false);

  // const areNotificationsRead = notifications.reduce(
  //   (acc, notif) => acc && notif.read,
  //   true
  // );

  React.useEffect(() => {
    if(address) setShowDisCon(true)
    else setShowDisCon(false)
  }, [address])

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 300);
  };

  const handleSignout = () => {
    signOut();
  };

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image
              src={image}
              className="user-avatar lg-avatar rounded-circle"
            />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };


  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">

          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">Admin</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                  <Link to={Routes.ResetPassword.path}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" />
                    Reset Password
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item className="fw-bold">
                  <Link to={Routes.Settings.path}>
                    <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                  </Link>
                </Dropdown.Item>

                <Dropdown.Item className="fw-bold" onClick={()=>{setShowWalletModal(true)}}>
                  <FontAwesomeIcon icon={faWallet} className="me-2" /> 
                  {
                    address ? Utils.shorten(address, true)  : 'Connect Wallet'
                  }                  
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={handleSignout}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className="text-danger me-2"
                  />
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
      <ConnectWalletModal
        show={showWalletModal}
        showDisCon={showDisCon}
        onDismiss={()=>{
          setShowWalletModal(false);
        }}
      />
    </Navbar>
  );
};

