import React from "react";
import "./ConnectWalletModal.scss";
import metamaskIcon from "../../assets/img/metamask-logo.png";

// import Dialog from "@material-ui/core/Dialog";
// import DialogContent from "@material-ui/core/DialogContent";
import { useSnackbar } from "notistack";

import { Row, Col, Card, Modal, Button, Container } from '@themesberg/react-bootstrap';

import ColorLoader from "../../assets/img/color-loader.gif";
// import { BiArrowBack } from "react-icons/bi";
// import { IconButton } from "@material-ui/core";
import { useAuth } from "../../context/AuthContext";
import { isMobile } from 'react-device-detect'


const ConnectWalletModal = ({ show, showDisCon, onDismiss }) => {
  const {
    selWallet,
    loading,
    connectMetamask,
    disConnectMetamask,
  } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  // const [showRegister, setShowRegister] = React.useState(false);
  const [userAddress, setUserAddress] = React.useState("");


  const onMetaMask = async () => {
    const res = await connectMetamask();
    if (res === false) {
      // setShowRegister(true)
    } else if (res === undefined) {

      enqueueSnackbar("There is no account.", { variant: "error" });

    } else if (res !== true && res) {

      enqueueSnackbar('Please check Metamask wallet and allow permission', { variant: "error" });

    } else if (res === true) {
      if (onDismiss) onDismiss();
    }
  }

  const onDisMetaMask = async () => {
    await disConnectMetamask();
    onDismiss();
  }
  
  const Connect = () => {
    if (isMobile && userAddress == '') {
      const dappUrl = "app.tribeone.io/";
      const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
      return (
        <a href={metamaskAppDeepLink} className="wallet-button white-border">
          <button
            className="wallet-button white-border"
            disabled={loading}
          >
            <img src={metamaskIcon} alt="" className="wallet-icons-metamask" />
            <span>Metamask</span>
            {loading && selWallet === "metamask" && <img src={ColorLoader} alt=""/>}
          </button>
        </a>
      );
    } else if (isMobile && userAddress != '') {
      return (
        <button
          className="wallet-button white-border"
          disabled={loading}
          onClick={onMetaMask}
        >
          <img src={metamaskIcon} alt="" className="wallet-icons-metamask" />
          <span>Metamask</span>
          {loading && selWallet === "metamask" && <img src={ColorLoader} alt=""/>}
        </button>
      );
    } 
    
    return (
      <button
        className="wallet-button white-border"
        disabled={loading}
        onClick={onMetaMask}
      >
        <img src={metamaskIcon} alt="" className="wallet-icons-metamask" />
        <span>Metamask</span>
        {loading && selWallet === "metamask" && <img src={ColorLoader} alt=""/>}
      </button>
    );
  }

  const ChooseWalletContent = () => {
    return (
      <div className="connect-wallet-modal-container">
        {!showDisCon ? (<div>
          <div className="wallet-title gilroy"> Connect Wallet </div>
          <div className="connect-wallet-btn-container">
            <Connect />            
          </div>
        </div>) : (<div>
          <div className="wallet-title gilroy"> Disconnect Wallet </div>
          <div className="connect-wallet-btn-container">
            <button
              className="wallet-button white-border"
              disabled={loading}
              onClick={onDisMetaMask}
            >
              <img src={metamaskIcon} alt="" className="wallet-icons-metamask" />
              <span>Metamask</span>
              {loading && selWallet === "metamask" && <img src={ColorLoader} alt=""/>}
            </button>
          </div>
        </div>)}        
      </div>
    );
  };

  return (

    <Modal as={Modal.Dialog} centered show={show} onHide={onDismiss}>
      <Modal.Header>
        {/* <Modal.Title className="h6">Connect wallet</Modal.Title> */}
        <Button variant="close" aria-label="Close" onClick={onDismiss} />
      </Modal.Header>
      <Modal.Body>
        <ChooseWalletContent />
      </Modal.Body>
      {/* <Modal.Footer> */}
        {/* <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
          Close
        </Button> */}
      {/* </Modal.Footer> */}
    </Modal>

    // <Dialog
    //   open={show}
    //   onClose={onDismiss}
    //   PaperProps={{
    //     style: {
    //       background: "rgba(233, 233, 233, 0.63)",
    //       boxShadow: "inset 0px 0px 36px rgba(255, 255, 255, 0.25)",
    //       backdropFilter: "blur(33px)",
    //       borderRadius: 17,
    //       paddingBottom:20,
    //       // height: 300,
    //     },
    //   }}
    //   aria-labelledby="alert-dialog-title"
    //   aria-describedby="alert-dialog-description"
    //   disableEscapeKeyDown
    //   disableBackdropClick
    // >
    //   <DialogContent>
    //      <ChooseWalletContent />
    //     <IconButton
    //       aria-label="delete"
    //       style={{ position: "absolute", top: 10, left: 10 }}
    //       onClick={onDismiss}
    //     >
    //       <BiArrowBack color={"white"} size={20} />
    //     </IconButton>
    //   </DialogContent>
    // </Dialog>
  );
};

export default ConnectWalletModal;
