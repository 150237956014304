import axios from "axios";

export const HOST = process.env.REACT_APP_API_HOST;

const HttpReq = async (url, method = "GET", token, data) => {
  const headers = { Accept: "application/json" };

  if (token) {
    headers.Authorization = "Bearer " + token;
  }
  let reqData = {
    url,
    method: method,
    headers,
  };

  if (data) {
    reqData.data = data;
  }
  return await axios(reqData);
};

export const HttpGet = async (url, token) => {
  return await HttpReq(url, "GET", token);
};

export const HttpPost = async (url, token, data) => {
  return await HttpReq(url, "POST", token, data);
};
export const HttpPut = async (url, token, data) => {
  return await HttpReq(url, "PUT", token, data);
};
export const HttpPatch = async (url, token, data) => {
  return await HttpReq(url, "PATCH", token, data);
};
export const HttpDelete = async (url, token, data) => {
  return await HttpReq(url, "DELETE", token, data);
};

const Api = {
  signin: async (email, pwd) => {
    const url = HOST + "user/auth/admin/login";
    const data = {
      username: email,
      pwd: pwd,
    };

    const res = await HttpPost(url, null, data);

    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  },

  updateCred: async (new_pwd, old_pwd, token) => {
    const url = `${HOST}user/auth/admin/updatecred`;
    const res = await HttpPost(url, token, {
      new_pwd: new_pwd,
      old_pwd: old_pwd,
    });

    if (res.data) {
      return res.data;
    } else {
      return res;
    }
  },
  getLoan: async (id, token) => {
    const url = `${HOST}loan/${id}`;
    const res = await HttpGet(url, token);

    return res;
  },
  userList: async (page = 1, limit = 20, search = "") => {
    const url = `${HOST}user/list/${page}/${limit}?search=${search}`;
    const res = await HttpGet(url);
    return res;
  },

  updateUser: async (userId, userData) => {
    const url = `${HOST}user/${userId}`;
    const res = await HttpPatch(url, null, userData);
    return res;
  },
  loanList: async (page = 1, limit = 20, token, search = '', searchField='', orderBy='', orderByDir='ASC', loanStatus='') => {
    const url = `${HOST}loan/list/${page}/${limit}?search=${search}&searchField=${searchField}&orderBy=${orderBy}&orderByDir=${orderByDir}&loanStatus=${loanStatus}`;

    const res = await HttpGet(url, token);
    return res;
  },
  favList: async (page = 1, limit = 20, token, search = '') => {
    const url = `${HOST}favorite/all_list/${page}/${limit}?search=${search}`;
    const res = await HttpGet(url, token);

    return res;
  },
  removeFav: async (id) => {
    const url = `${HOST}favorite/admin/${id}`;
    const res = await HttpDelete(url);
    return res;
  },
  nftList: async (search, token, page = 1, limit = 20) => {
    const url = `${HOST}nft/list/${page}/${limit}`;
    
    const res = await HttpPost(
      url,
      token,
      search ? { search: search } : undefined
    );
    return res;
  },
  surveyList: async (page = 1, limit = 20, search = '') => {
    const url = `${HOST}survey/list/${page}/${limit}?search=${search}`;
    const res = await HttpGet(url);
    return res;
  },

  getAgentForLoan:async(loanId, trx_hash) => {
    const url = `${HOST}util/get_agent_for_loan/${trx_hash}/${loanId}`;
    const res = await HttpGet(url);
    return res;
  },

  isApprovableLoan:async(loanId) => {
    const url = `${HOST}util/approvable/${loanId}`;
    const res = await HttpGet(url);
    return res;
  },

  getMsgValueAcceptedForLoan:async(loanId) => {
    const url = `${HOST}util/loan_relay_manual/msg_value/${loanId}`;
    const res = await HttpGet(url);
    return res;
  },

  approveLoanManual: async(trxHash, agent, loanId, token)=>{
     const url = `${HOST}util/loan_approve_manual/${trxHash}/${agent}/${loanId}`
     const res = await HttpPost(
      url,
      token,
      undefined
    );
    return res;
  },

  relayLoanManual: async(trxHash, loanId, accepted, agent, token)=>{
     const url = `${HOST}util/loan_relay_manual/${trxHash}/${loanId}`
     const res = await HttpPost(
      url,
      token,
      {
        accepted,
        agent
      }
    );
    return res;
  },

  removeSurvey: async (id) => {
    const url = `${HOST}survey/admin/${id}`;
    const res = await HttpDelete(url);
    return res;
  },
  transList: async ({ page = 1, limit = 20, token, search, searchField }) => {

    const url = `${HOST}transaction/all_list/${page}/${limit}`;

    const res = await HttpPost(url, token, { search, searchField });
    return res;
  },

  getEvents: async ({ page = 1, limit = 20, token, search, searchField }) => {

    const url = `${HOST}event/all_list/${page}/${limit}`;

    const res = await HttpPost(url, token, { search, searchField });
    return res;
  },
  removeEvents: async ({ ids, token }) => {

    const url = `${HOST}event/remove_list`;
    const res = await HttpPost(url, token, { ids });
    return res;
  },

  // loanApproved: async (loanId, address, trxhash, token) => {
  //   const url = `${HOST}loan/lap/${loanId}/${address}/${trxhash}`;
  //   return await HttpPost(url, token);
  // },

  // relayLoanForce: async (loanId, token) => {
  //   const url = `${HOST}util/relaydata_try`;

  //   const res = await HttpPost(
  //     url,
  //     token,
  //     { loanId: loanId }
  //   );
  //   return res;
  // },

  adminCancelRequest: async (loanId, token) => {
    const url = `${HOST}loan/cancel_request/${loanId}`;
    const res = await HttpPost(url, token);
    return res;
  },

  getETHUSD: async () => {
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd";

    const resEthUsd = await HttpGet(url);

    if (
      resEthUsd &&
      resEthUsd.status == 200 &&
      resEthUsd.data &&
      resEthUsd.data.ethereum &&
      resEthUsd.data.ethereum.usd
    ) {
      return resEthUsd.data.ethereum.usd;
    } else {
      alert("Failed to get the rate for eth to usd.");
      return null;
    }
  },

  createSellOrder: async ({
    tokenId,
    tokenContractAddress,
    startAmount,
    schemaName,
    buyerAgentWallet,
    loanId,
    token,
  }) => {
    const url = HOST + "util/create_sell_order";
    const data = {
      tokenId,
      tokenContractAddress,
      startAmount,
      schemaName,
      loanId,
      buyerAgentWallet,
    };

    try {
      const res = await HttpPost(url, token, data);
      if (res.data) {
        return res.data;
      } else {
        return res;
      }
    } catch (ex) {
      return {
        status: 400,
        error: "Failed " + ex.message,
      };
    }
  },

  logList: async (page = 1, limit = 20) => {
    const url = `${HOST}log/search`;
    const data = {
      page,
      limit,
    };
    const res = await HttpPost(url, null, data);
    return res;
  },

  getConf: async (token) => {
    const url = `${HOST}confdata/details`;
    const res = await HttpGet(url, token);
    return res;
  },

  updateConf: async ({ cron_test, network_test, secs_per_day_cron, token }) => {
    const url = `${HOST}confdata/update`;
    const data = {
      cron_test,
      network_test,
      secs_per_day_cron,
    };

    const res = await HttpPatch(url, token, data);
    return res;
  },

  removeLoan: async (id, token) => {
    const url = `${HOST}loan/record/rem/${id}`;
    const res = await HttpDelete(url, token);
    return res;
  },
  removeLoans: async (ids, token) => {
    const url = `${HOST}loan/remove_list`;
    const res = await HttpPost(url, token,  {ids});
    return res;
  },

  getMailConf: async (token) => {
    const url = `${HOST}confdata/ml_config`;
    const res = await HttpGet(url, token);
    return res;
  },

  getNodesStatus: async () => {
    const url = `${HOST}util/check_all_node`;
    const res = await HttpGet(url);
    return res;
  },

  getLogFiler: async () => {
    const url = `${HOST}event/getLogFilter`;
    const res = await HttpGet(url);
    return res;
  },

  getHealth: async () => {
    const url = `${HOST}`;
    const res = await HttpGet(url);
    return res;
  },


  getLoanIdFromRand: async(randId)=>{
    if (!randId) {
      throw new Error('Invalid random id.')
    }
    const url = `${HOST}mail/loan_id/${randId}`;
    const res = await HttpGet(url);
    return res;
  },


  getLogFiles: async () => {
    const url = `${HOST}log/all`;
    const res = await HttpGet(url);
    return res;
  },

  restoreLoanFromEvent: async (blockNumber, txHash) => {

    if (!blockNumber || !txHash) {
      throw new Error(`Empty blockNumberm or tx hash ${blockNumber}, ${txHash}`)
    }
    const url = `${HOST}event/process/manual`;
    const data = {
      blockNumber, txHash
    };
    const res = await HttpPost(url, null, data);
    return res;
  },

};

export default Api;
