import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
    Col,
    Row,
    Form,
    Breadcrumb,
    InputGroup,
    Modal,
    Button,
} from "@themesberg/react-bootstrap";
import { Routes } from "../../routes";
import Api from "../../apis/Api";
import { LoanTable } from "./LoanTable";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setCurLoan } from "../../state/rootSlice";
import { useAuth } from "../../context/AuthContext";
import { DropDownLimitReloader } from "../favorites/index";
import { useSnackbar } from "notistack";

const SearchFieldsTransaction = [
    { label: "ALL", slug: null },
    { label: "Loan Id", slug: "loan.contract_loan_id" },
    { label: "User Id", slug: "user.id" },

    { label: "Status", slug: "loan.status" },
    { label: "Token Id", slug: "loan.token_id" },
    { label: "Token Address", slug: "loan.asset_contract_address" },
    { label: "Token Name", slug: "nft.tokenName" },

    { label: "Market Place", slug: "loan.market_place" },
    { label: "Net Mode", slug: "loan.net_mode" },
    { label: "InterestRate", slug: "loan.interest_rate" },
    { label: "LTV", slug: "loan.ltv" },
    { label: "Loan Duration", slug: "loan.loan_duration" },
    { label: "User Wallet", slug: "loan.user_wallet_address" },
];

export const LoanStatus = {
    AVOID_ZERO: "AVOID_ZERO",
    LISTED: "LISTED",
    APPROVED: "APPROVED",
    DEFAULTED: "DEFAULTED",
    LOANACTIVED: "LOANACTIVED",
    LOANPAID: "LOANPAID",
    FAILED: "FAILED",
    CANCELLED: "CANCELLED",
    LIQUIDATION: "LIQUIDATION",
    POSTLIQUIDATION: "POSTLIQUIDATION",
    WITHDRAWN: "WITHDRAWN",
    ONSALE: "ONSALE",
    RESTWITHDRAWN: "RESTWITHDRAWN",
    RESTLOCKED: "RESTLOCKED",
    REJECTED: "REJECTED",
    BUY_FAILED: "BUY_FAILED",
};

export default function Loans() {
    const dispatch = useDispatch();
    const limit = useSelector((state) => state.root.limit);
    const { token } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    const [showModal, setShowModal] = React.useState(false);
    const [removeLoanId, setRemoveLoanId] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [searchField, setSearchField] = React.useState();

    const [selStatus, setSelStatus] = React.useState("");

    const [orderBy, setOrderBy] = React.useState({
        field: "cast(loan.contract_loan_id as unsigned)",
        orderByDir: "ASC",
    });

    const history = useHistory();

    React.useEffect(() => {
        if (token) {
            loadData();
        }
    }, [page, limit, token, orderBy, selStatus]);

    const loadData = async () => {
        try {
            setLoading(true);
            let _search = search;

            if (searchField == "loan.contract_loan_id") {
                if (search && search.length > 10) {
                    // todo get loan id from Random ID
                    const resRand = await Api.getLoanIdFromRand(search);
                    const loanId = resRand.data;
                    console.log({ resRand, loanId });
                    _search = loanId;
                }
            }

            const res = await Api.loanList(
                page,
                limit,
                token,
                _search,
                searchField,
                orderBy.field,
                orderBy.orderByDir,
                selStatus
            );
            setLoading(false);
            if (res.status == 200 && res.data.status == 200) {
                setRows(res.data.data);
                setTotalRows(res.data.total);
            } else {
                setRows([]);
                alert("Error while loading : " + res.error);
            }
        } catch (ex) {
            setLoading(false);
            alert(ex.message);
        }
    };

    const handleRemove = (loanId) => {
        setRemoveLoanId(loanId);
        setShowModal(true);
    };

    const handleConfirmRemove = async () => {
        setShowModal(false);
        if (!token || !removeLoanId) {
            return;
        }

        const res = await Api.removeLoan(removeLoanId, token);
        if (res.data && res.data.status == 200) {
            enqueueSnackbar("Success to remove loan.", {
                variant: "success",
            });
        } else {
            enqueueSnackbar(
                res.data && res.data.error
                    ? res.data.error
                    : "Failed to remove.",
                {
                    variant: "error",
                }
            );
        }
        loadData();
    };

    const onCloseModal = () => {
        setShowModal(false);
    };

    const onSearch = async () => {
        await loadData();
    };

    const onRemoveSelectedItem = async () => {
        const chkBoxes = document.getElementsByClassName("chk_loan_item");
        let ids = [];
        for (let one of chkBoxes) {
            if (one.checked) {
                ids.push(one.id.replace("chk_loan_", ""));
            }
        }

        console.log("selected loanids: ", { ids });
        if (ids.length == 0) {
            return;
        }

        if (
            window.confirm(
                "Are you sure to remove this loans on backend? But this is not removed on chain."
            )
        ) {
            setLoading(true);
            const res = await Api.removeLoans(ids, token);
            await loadData();
            setLoading(false);
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Loans</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Loans</h4>
                </div>
            </div>

            <div className="table-settings mb-4">
                <Row className=" align-items-center">
                    <Col xs={3} md={4} lg={3} xl={4}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                            <Button
                                variant="outline-primary"
                                id="button-addon2"
                                onClick={onSearch}
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col xs={3} md={2} lg={3} xl={2}>
                        <Form.Group className="w-100">
                            <Form.Select
                                value={searchField}
                                onChange={(e) => {
                                    setSearchField(e.target.value);
                                }}
                            >
                                {SearchFieldsTransaction.map((field) => {
                                    return (
                                        <option
                                            value={field.slug}
                                            key={field.slug}
                                        >
                                            {field.label.toUpperCase()}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs={3} md={2} lg={2} xl={2}>
                        <Form.Group className="w-100">
                            <Form.Select
                                value={selStatus}
                                onChange={(e) => {
                                    setSelStatus(e.target.value);
                                }}
                            >
                                <option value={""}>ALL</option>
                                {Object.keys(LoanStatus).map((key) => {
                                    if (
                                        LoanStatus.AVOID_ZERO == LoanStatus[key]
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <option
                                            value={LoanStatus[key]}
                                            key={LoanStatus[key]}
                                        >
                                            {LoanStatus[key]}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col
                        xs={3}
                        md={4}
                        lg={4}
                        xl={4}
                        className="ps-md-0 text-end"
                    >
                        <DropDownLimitReloader
                            loading={loading}
                            loadData={loadData}
                        />
                    </Col>
                </Row>
            </div>

            <LoanTable
                rows={rows}
                total={totalRows}
                page={page}
                limit={limit}
                onChangePage={(newPage) => {
                    setPage(newPage);
                }}
                onDetails={(loan) => {
                    dispatch(setCurLoan(loan));

                    history.push(Routes.LoansDetail.path);
                }}
                onRemove={(loan) => {
                    if (loan) {
                        setShowModal(true);
                        handleRemove(loan.id);
                    }
                }}
                onChangeOrderBy={(orderBy, orderByDir) => {
                    setOrderBy({ field: orderBy, orderByDir: orderByDir });
                }}
            />
            <div className="row">
                <div className="col text-right">
                    <Button variant="danger" onClick={onRemoveSelectedItem}>
                        Remove Selected
                    </Button>
                </div>
            </div>
            <Modal
                as={Modal.Dialog}
                centered
                show={showModal}
                onHide={onCloseModal}
            >
                <Modal.Header>
                    <Modal.Title className="h6">Confirm Delete</Modal.Title>
                    <Button
                        variant="close"
                        aria-label="Close"
                        onClick={onCloseModal}
                    />
                </Modal.Header>
                <Modal.Body>
                    <p>
                        If this loan might have transaction data, it will occur
                        problem when transcation data is removed. Are you sure
                        to remove this?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleConfirmRemove}>
                        Yes
                    </Button>
                    <Button
                        variant="secondary"
                        className="text-gray ms-auto"
                        onClick={onCloseModal}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
