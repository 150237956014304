import React,{useState, useEffect} from 'react'
import Api from "../apis/Api";
import getWeb3 from "../context/getWeb3";
import WalletConnectProvider from "@walletconnect/web3-provider";


export const TOKENKEY = "t_k_err";
export const USERKEY = "u_s_re_k";
export const BalanceKey = "bal_k_ctxt";

export default function useAuthValue(){


  let savedWallet = window.sessionStorage.getItem("wallet");
  let savedWalletNet = window.sessionStorage.getItem("wallet_net");
  
  
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [token, setToken] = useState();
  
  const [selWallet, setSelWallet] = React.useState(savedWalletNet);
  const [address, setAddress] = React.useState(savedWallet);
  const [shortAddr, setShortAddr] = React.useState();
  const [balance, setBalance] = React.useState();
  const [requireSignup, setRequireSignup] = React.useState(false);
  const [balanceSymbol, setBalanceSymbol] = React.useState("ETH");
  const [isRightNet, setIsRightNet] = React.useState(undefined);


  const signOut = () => {

    setToken(undefined);
    setLoading(false);
    
    setUser(undefined);
    sessionStorage.removeItem(TOKENKEY);
    sessionStorage.removeItem(USERKEY);
  };

  const signIn = async (email, pwd) => {

    const res = await Api.signin(email, pwd)

    if(res.status == 200 && res.user.role === 'admin'){
      storeToken(res.user.token)
      storeUser(res.user)
      return true;      
    }
    return false;
  };

  const storeToken = (val) => {
    setToken(val);
    sessionStorage.setItem(TOKENKEY, val);
  };


  const storeUser = (obj) => {
    setUser(obj);
    const val = JSON.stringify(obj);
    sessionStorage.setItem(USERKEY, val);
  };


  const storeBalance = (val) => {
    setBalance(val);
    window.sessionStorage.setItem(BalanceKey, val);
  };

  const connectWalletProvider = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setSelWallet("wallet");
    setLoading(true);

    const web3Provider = new WalletConnectProvider({
      rpc: {
        1: "https://mainnet.infura.io/v3/4b93059928a54b9e937b85abdb6e6756",
      },
      chainId: 1,
      bridge: "https://bridge.walletconnect.org",
    });

    localStorage.setItem("wallet", "trust");

    web3Provider
      .enable()
      .then((data) => {

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // const checkingUserByWallet = async (wallet) => {
  //   const res = await Api.login(wallet);
  //   setLoading(false);
  //   if (res && res.status === 200) {
  //     storeUser(res.user);
  //     storeToken(res.user.token);

  //     return true;
  //   } else {
  //     //* show user signup form
  //     // setShowRegister(true);

  //     setRequireSignup(true);

  //     return false;
  //   }
  // };

  const disConnectMetamask = async () => {
    clear();
    localStorage.clear();
  }

  const connectMetamask = async () => {
    setSelWallet("metamask");
    try {
      setLoading(true);

      const web3 = await getWeb3();
      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();

      if (accounts.length > 0) {

        setAddress(accounts[0]);
        setSelWallet("meta_mask");
        window.sessionStorage.setItem("wallet", accounts[0]);
        window.sessionStorage.setItem("wallet_net", "meta_mask");

        const shortAccAddress =
          accounts[0].slice(0, 4) + ". . ." + accounts[0].slice(38, 42);
        setShortAddr(shortAccAddress);

        // const checkResult = await checkingUserByWallet(accounts[0]);
      
        await checkNetwork();
        setLoading(false);
        return true;
        // return checkResult;
      } else {
        setLoading(false);
        return undefined;
      }
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const clear = () => {
    setAddress(undefined);
    setBalance(undefined);
    setShortAddr(undefined);
    setSelWallet(undefined);

  };


  async function checkNetwork() {
    const web3 = await getWeb3();
    const chainInfo = await getNetwork(web3);
    if (!chainInfo) {
      setIsRightNet(false);
      return false;
    }
    setBalanceSymbol(chainInfo.symbol);
    const isLive = process.env.REACT_APP_NETWORK === 'live';
    const _isRightNet = isLive && chainInfo.ChainId == 1 || !isLive && chainInfo.ChainId == 4;

    setIsRightNet(_isRightNet);
    return _isRightNet
  }

  const getNetWorkChangeMessage = ()=>{

    if (!isRightNet){
      const mode = process.env.REACT_APP_NETWORK === 'live' ? 'Mainnet' : 'Rinkeby Testnet';
      const msg = `You are not in right network, please change network to ${mode}`;
      return msg;
    } else {
      return null;
    }
  }



  async function getNetwork(web3) {
    const chainID = await web3.eth.net.getId();
    const chainInfo = getNetworkName(chainID);

    return chainInfo;

  }

  function getNetworkName(chainID) {
    //ChainType is an interface that has a chainId and chainName.
    const network = [
      { ChainId: 1, ChainName: "Ethereum Mainnet",symbol:"ETH" },
      { ChainId: 3, ChainName: "Ropsten Testnet",symbol:"ETH" },
      { ChainId: 4, ChainName: "Ethereum Rinkeby", symbol:"ETH" },
      { ChainId: 56, ChainName: "Binance Smart Chain", symbol:"BNB" },
      { ChainId: 97, ChainName: "Binance Smart Chain Testnet", symbol:"BNB" },
      { ChainId: 42, ChainName: "Kovan Testnet", symbol:"ETH" },
      { ChainId: 80001, ChainName: "Polygon Mumbai Testnet", symbol:"MATIC" },
      { ChainId: 137, ChainName: "Polygon Mainnet", symbol:"MATIC" },
    ];

    return network.find((i) => i.ChainId === chainID);
  }


  const accountsChanged = (accounts) => {

    if (accounts.length === 0) {
      clear();
    } else {
      setAddress(accounts[0]);
      window.sessionStorage.setItem("wallet", accounts[0]);
      // checkingUserByWallet(accounts[0]);

    }
  }


  const getBalance = async () => {

    try {
      const web3 = await getWeb3();
      const accounts = await web3.eth.getAccounts();
      setAddress(accounts[0]);
      const val = await web3.eth.getBalance(accounts[0]);
      const balance = web3.utils.fromWei(val);
      storeBalance(parseFloat(balance).toFixed(4));
    } catch (ex) {
      console.log("while getBalance: ex => address: " + address, " ex:", ex);
    }
  }

  React.useEffect(() => {

    (async () => {
      try {
        if (address) {
          await getBalance();
          // await checkingUserByWallet(address);
          await checkNetwork();
        } else {
          clear();
        }
      } catch (ex) {
        console.log("error before clean :", ex);
        clear();
      }
    })();
  }, [address]);

  const onChainChanged = async (chainId) => {
    
    await getBalance();
    await checkNetwork();
  }

  const onEthMessage = async (message) => {
  }

  const onDisconnect = async (error) => {
  }

  const onConnect = async (connectInfo) => {
    // alert('onConnect :  ' + JSON.stringify(connectInfo, null, 2))
  }

  React.useEffect(() => {

    setLoading(true)
    const savedToken = sessionStorage.getItem(TOKENKEY);
    
    setToken(savedToken); 
    const savedUser = sessionStorage.getItem(USERKEY);

    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }else{
      setUser(undefined);
    }

    setLoading(false)
    setTokenLoaded(true)


    if (window.ethereum) {

      window.ethereum.removeListener("accountsChanged", accountsChanged);
      window.ethereum.on("accountsChanged", accountsChanged);

      window.ethereum.removeListener("connect", onConnect);
      window.ethereum.on("connect", onConnect);

      window.ethereum.removeListener("disconnect", onDisconnect);
      window.ethereum.on("disconnect", onDisconnect);

      window.ethereum.removeListener("chainChanged", onChainChanged);
      window.ethereum.on("chainChanged", onChainChanged);

      window.ethereum.removeListener("message", onEthMessage);
      window.ethereum.on("message", onEthMessage);
    }

    if (savedWalletNet === "meta_mask") {
      connectMetamask();
    }




    return () => {

    }
  }, []);

  return  {
    user,
    loading,
    token,
    signIn,
    signOut,
    storeToken,
    storeUser,

    selWallet,
    address,
    shortAddr,
    connectMetamask,
    disConnectMetamask,
    connectWalletProvider,
    balance,
    balanceSymbol,
    storeUser,
    storeToken,
    requireSignup,
    setRequireSignup,
    // checkingUserByWallet,
    isRightNet,
    getNetWorkChangeMessage,
    tokenLoaded
  };
}