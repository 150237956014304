import { BigNumber } from "bignumber.js"
import React, { createContext, useContext, useEffect } from "react";
import { AggregatorV3Interface_ABI, AggregatorV3Interface_Addr } from '../config/AggregatorV3Interfac';
import { ASSETMANAGER_ABI, ASSETMANAGER_ADDRESS } from "../config/AssetManager";
import { ERC1155 } from "../config/ERC1155";
import { ERC20 } from "../config/ERC20";
import { ERC721 } from "../config/ERC721";
import { HAKA_ABI, HAKA_ADDRESS } from "../config/HAKA";
import { SIGWALLET_ABI, SIGWALLET_ADDRESS } from "../config/MultiSigWallet";
import { TRIBEONE_ABI, TRIBEONE_ADDRESS } from "../config/TribeOne";
import getWeb3 from "./getWeb3";
import { useAuth } from "./AuthContext";

const ZERO_ADDR = "0x0000000000000000000000000000000000000000";
const GasMultiple = 2;

const TENOR_UNIT = 4 * 7 * 24 * 60 * 60;
export const NFT_TYPE = {
  ERC721: 0,
  ERC1155: 1,
};


export const LoanStatusInContract = {
  AVOID_ZERO:0, // just for avoid zero
  LISTED:1, // after the loan has been created --> the next status will be APPROVED
  APPROVED:2, // in this status the loan has a lender -- will be set after approveLoan(). loan fund => borrower
  LOANACTIVED:3, // NFT was brought from opensea by agent and staked in TribeOne - relayNFT()
  LOANPAID:4, // loan was paid fully but still in TribeOne
  WITHDRAWN:5, // the final status, the collateral returned to the borrower or to the lender withdrawNFT()
  FAILED:6, // NFT buying order was failed in partner's platform such as opensea...
  CANCELLED:7, // only if loan is LISTED - cancelLoan()
  DEFAULTED:8, // Grace period = 15 days were passed from the last payment schedule
  LIQUIDATION:9, // NFT was put in marketplace
  POSTLIQUIDATION:10, /// NFT was sold
  RESTWITHDRAWN:11, // user get back the rest of money from the money which NFT set is sold in marketplace
  RESTLOCKED:12,// Rest amount was forcely locked because he did not request to get back with in 2 weeks (GRACE PERIODS)
}


window.tribeone = undefined;
export function ContractConfig() {
  const { address } = useAuth();
  const [tribeone, setTribeOne] = React.useState();

  const [web3, setWeb3] = React.useState();
  const [hakaCont, setHakaCont] = React.useState();
  const [sigwallet, setSigWallet] = React.useState();
  const [erc20Cont, setErc20Cont] = React.useState();
  const [assetManager, setAssetManager] = React.useState();

  const getTribeOne = async () => {
    const _web3 = await getWeb3();
    const tribeoneCont = new _web3.eth.Contract(TRIBEONE_ABI, TRIBEONE_ADDRESS);
    window.tribeone = tribeoneCont;
    return tribeoneCont;
  };

  const removeLeftZeroPad = (str) => {
    let res = '';
    let isFirstZero = true;
    let checkStr = str;
    let prefix = '';
    if (str.startsWith('0x')) {
      checkStr = str.slice(2);
      prefix = '0x';
    }
    for (let one of checkStr) {
      if (one == '0' && isFirstZero) {
        continue;
      } else {
        isFirstZero = false;
        res += one;
      }
    }

    return prefix + res;
  }

  const getLoanIdFromCreateTransaction = async (hash) => {
    const _web3 = await getWeb3();
    return new Promise((resolve, reject) => {
      _web3.eth.getTransactionReceipt(hash).then(receipt => {

        if (receipt.logs && receipt.logs.length == 3 && receipt.logs[2].topics && receipt.logs[2].topics.length >= 3) {

          let loanIdHex = removeLeftZeroPad('' + receipt.logs[2].topics[1]);
          console.log({ loanIdHex });

          resolve(new BigNumber(loanIdHex).toNumber());
          return;
        }
        reject('receipt is not correct for LoanCreated transaction.');
      })
        .catch(ex => { reject(ex) })
    })
  }

  const getChainLinkETHUSD = async () => {
    const _web3 = await getWeb3();
    const priceFeed = new _web3.eth.Contract(AggregatorV3Interface_ABI, AggregatorV3Interface_Addr);

    return new Promise((resolve, reject) => {

      priceFeed.methods.latestRoundData().call()
        .then((roundData) => {
          // Do something with roundData
          const ethUsd = new BigNumber(roundData).dividedBy(10 ** 18).pow(-1).toNumber();
          resolve(ethUsd);
        }).catch((ex) => {
          console.log('Exception while price feed: ', ex);
          reject(ex);
        })
    });
  }

  const getSigwallet = async () => {
    const _web3 = await getWeb3();
    const sigwallet = new _web3.eth.Contract(SIGWALLET_ABI, SIGWALLET_ADDRESS);
    return sigwallet;
  };

  const getAssetManager = async () => {
    const _web3 = await getWeb3();
    const assetManager = new _web3.eth.Contract(
      ASSETMANAGER_ABI,
      ASSETMANAGER_ADDRESS
    );
    return assetManager;
  };


  const getNFTContract = async (tokenAddr, schemaType) => {
    const _web3 = await getWeb3();

    let abi = null;
    if (schemaType.toLowerCase() == "erc721") {
      abi = ERC721;
    } else if (schemaType.toLowerCase() == "erc1155") {

      abi = ERC1155;
    } else {

      return null;
    }
    
    const nftContract = new _web3.eth.Contract(
      abi,
      tokenAddr
    );
    return nftContract;
  }

  const checkNFTOwner = async (tokenAddr, schemaType, tokenId, ownerAddr) => {
    const nftContract = await getNFTContract(tokenAddr, schemaType);

    if (!nftContract) {
      return null;
    }
    let res = undefined;

    if (schemaType.toUpperCase() == 'ERC1155') {

      if (ownerAddr != ZERO_ADDR) {
        res = await nftContract.methods.balanceOf(ownerAddr, tokenId).call();
        res = !!res;
      } else {
        res = true;
      }
    } else if (schemaType.toUpperCase() == 'ERC721') {

      res = await nftContract.methods.ownerOf(tokenId).call();
      if(!res){
        return false;
      }
      res = res.toUpperCase() == ownerAddr.toUpperCase();

    } else {
      res = null;
    }

    return res;
  }

  const _approvalForAll = (tokenAddr, schemaType, receiver, agent, transactionHashCallback) => {

    return new Promise(async(resolve, reject) => {

      const nftContract = await getNFTContract(tokenAddr, schemaType);
      console.log({
        nftContract,
        setApprovalForAll: nftContract.setApprovalForAll,
        tokenAddr, schemaType
      })

      nftContract.methods
        .setApprovalForAll(
          receiver, true
        )
        .send({ from: agent })
        .on("transactionHash", function (hash) {
          if (transactionHashCallback) {
            transactionHashCallback(hash);
          }
        })
        .on("error", function (error, receipt) {
          reject({ error, receipt });
        })
        .then((receipt) => {
          resolve(receipt);
        });

    })
  }


  const isApprovedForAll = async (schema, tokenAddr, holder, operator) => {
    
    return new Promise(async (resolve, reject) => {
      const nftCont = await getNFTContract(tokenAddr, schema);
      try {
        const res = await nftCont.methods.isApprovedForAll(holder, operator).call();
        resolve(res);
      } catch (ex) {
        reject(ex);
      }
    });
  };




  const setApprovalForAll = async (tokenAddr, schemaType, receiver, agent) => {
    try {
     
      const res = await _approvalForAll(tokenAddr, schemaType, receiver, agent, (hash)=>{
        console.log('_approvalNFTForAll Tranasction hash :', hash);
      })
    
      // const res = await tx.wait();
      console.log('Result of approvalNFTForAll : ', res);
      if (res.events && res.events.ApprovalForAll && res.events.ApprovalForAll.returnValues && res.events.ApprovalForAll.returnValues){
        return res.events.ApprovalForAll.returnValues.approved;
      }
      return false;
    } catch (ex) {
      console.log(ex);
      return null;
    }

  }

  const initContract = async () => {

    try {
      const _web3 = await getWeb3();
      setWeb3(_web3);
      const tribeoneCont = new _web3.eth.Contract(
        TRIBEONE_ABI,
        TRIBEONE_ADDRESS
      );
      setTribeOne(tribeoneCont);
      const sigwalletCont = new _web3.eth.Contract(
        SIGWALLET_ABI,
        SIGWALLET_ADDRESS
      );
      setSigWallet(sigwalletCont);
      const assetCont = new _web3.eth.Contract(
        ASSETMANAGER_ABI,
        ASSETMANAGER_ADDRESS
      );
      setAssetManager(assetCont);
      const hakaContract = new _web3.eth.Contract(HAKA_ABI, HAKA_ADDRESS);
      setHakaCont(hakaContract);

      const erc20Contract = new _web3.eth.Contract(ERC20, HAKA_ADDRESS);
      setErc20Cont(erc20Contract);

      return true;
    } catch (ex) {
      return false;
    }
  };

  const getLoans = (loanId) => {

    return new Promise(async (resolve, reject) => {

      let _tribeOne = tribeone;
      if (!_tribeOne) {
        _tribeOne = await getTribeOne();
      }

      try {
        const res = await _tribeOne.methods.getLoans(loanId).call();
        console.log('getLoans : ', res)
        resolve(res);
      } catch (ex) {
        console.log('Exception : getLoans >> ',ex)
        reject(ex);
      }
    })
  }

  const getLoanRules = async (loanid) => {
    let _tribeOne = tribeone;
    if (!_tribeOne) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods.getLoanRules(loanid).call();
      return res;
    } catch (ex) {
      return null;
    }
  };

  const getLoanAsset = async (loanid) => {
    let _tribeOne = tribeone;
    if (!tribeone) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods.getLoanAsset(loanid).call();
      return res;
    } catch (ex) {
      return null;
    }
  };

  const getCollateralAsset = async (loanid) => {
    let _tribeOne = tribeone;
    if (!tribeone) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods.getCollateralAsset(loanid).call();
      return res;
    } catch (ex) {
      return null;
    }
  };

  const getLoanNFTCount = async (loanid) => {
    let _tribeOne = tribeone;
    if (!tribeone) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods.getLoanNFTCount(loanid).call();
      return res;
    } catch (ex) {
      return null;
    }
  };

  const getLoanNFTItem = async (loanid) => {
    // nftItemId : nftItemIndex  : default 0
    let _tribeOne = tribeone;
    if (!tribeone) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods
        .getLoanNFTItem(loanid)
        .call();
      return res;
    } catch (ex) {
      return null;
    }
  };

  const totalDebt = async (loanid) => {
    let _tribeOne = tribeone;
    if (!tribeone) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods.totalDebt(loanid).call();
      return res;
    } catch (ex) {
      console.log("Exception while totalDebt at contract Tribeone: ", ex);
      return null;
    }
  };

  const getPayInstallmentAmount = async (loanId) => {
    //* return {_amount:BigNumber, expectedNr} || null

    try {
      const _totalDebt = await totalDebt(loanId);

      const loan = await getLoans(loanId);
      const expectedNr = await expectedNrOfPayments(loan);

      if (!expectedNr) {
        return null;
      }


      const expectedAmount = new BigNumber(_totalDebt).multipliedBy(expectedNr).dividedBy(parseInt(loan['loanRules']['tenor']));
      const paidAmount = new BigNumber(loan['paidAmount']);

      const _amount = expectedAmount.minus(paidAmount);

      return { _amount, expectedNr };
    } catch (ex) {
      console.log('Exception: ContractContext: getpayinstallmentAmount:: ', ex.message);
      return null;
    }

  }


  const expectedLastPaymentTime = async (loanid) => {
    let _tribeOne = tribeone;
    if (!tribeone) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods
        .expectedLastPaymentTime(loanid)
        .call();
      return res;
    } catch (ex) {
      console.log("Exception while totalDebt at contract Tribeone: ", ex);
      return null;
    }
  };

  const finalDebtAndPenalty = async (loanid) => {
    let _tribeOne = tribeone;
    if (!tribeone) {
      _tribeOne = await getTribeOne();
    }
    try {
      const res = await _tribeOne.methods.finalDebtAndPenalty(loanid).call();
      return res;
    } catch (ex) {
      console.log(
        "Exception while finalDebtAndPenalty at contract Tribeone: ",
        ex
      );
      return null;
    }
  };

  const expectedNrOfPayments = async (loan) => {

    try {
      let loanStart = loan.loanStart;
      const d = new Date();
      let utcTimeStamp = Math.floor(d.getTime() / 1000);
      let _expected = Math.floor((utcTimeStamp - loanStart) / TENOR_UNIT);
      let _tenor = loan.loanRules.tenor;

      return _expected > _tenor ? _tenor : _expected;
    } catch (ex) {
      console.log(
        "Exception while expectedNrOfPayments at contract Tribeone: ",
        ex
      );
      return null;
    }
  };

  const isAdmin = async (address) => {

    return new Promise(async (resolve, reject) => {

      let _tribeOne = tribeone;
      if (!_tribeOne) {
        _tribeOne = await getTribeOne();
      }

      try {
        const res = await _tribeOne.methods.isAdmin(address).call();
        resolve(res);
      } catch (ex) {
        reject(ex);
      }
    })

  }

  const approveLoan = async (
    _loanId,
    _amount,
    _agent,
    from,
    transactionHashCallback,
  ) => {

    let _tribeOne = tribeone;
    if (!_tribeOne) {
      _tribeOne = await getTribeOne();
    }

    return new Promise((resolve, reject) => {

      _tribeOne.methods
        .approveLoan(
          _loanId, _amount, _agent
        )
        .send({ from: from })
        .on("transactionHash", function (hash) {
          if (transactionHashCallback) {
            transactionHashCallback(hash, _loanId);
          }
        })
        .on("error", function (error, receipt) {
          reject({ error, receipt });
        })
        .then((receipt) => {
          resolve(receipt);
        });

    })

  }

  const getExpectedLastPaymentTimeLoan = async (loanid) => {

    let _tribeOne = tribeone;
    if (!_tribeOne) {
      _tribeOne = await getTribeOne();
    }

    try {
      const lastPayTimestampSecs = await _tribeOne.methods.expectedLastPaymentTime(loanid).call();

      console.log('getExpectedLastPaymentTimeLoan : loanid : > ', loanid, lastPayTimestampSecs);
      return lastPayTimestampSecs;

    } catch (ex) {
      console.log('Exception while getExpectedLastPaymentTimeLoan at contract Tribeone: ', ex);
      return null;
    }
  };


  const checkLoanSettableToDefaulted = async (loanId) => {
    try {
      const loanDetail = await getLoans(loanId);

      if (loanDetail == null) {
        console.log("@checkLoanSettableToDefaulted >> Loan is invalid.");
        return false;
      }

      const lastPayTime = await getExpectedLastPaymentTimeLoan(loanId);
      if (lastPayTime == null) {
        console.log("@checkLoanSettableToDefaulted >> Loan last payment time is invalid.");
        return false;
      }
      const nowSeconds = (Date.now() / 1000).toFixed(0);
      return loanDetail.status == LoanStatusInContract.LOANACTIVED && nowSeconds > lastPayTime;
    } catch (ex) {
      console.log('exception : at checkLoanSettableToDefaulted : ', ex);
      return null;
    }

  }


  //* Using Task Infura Provider
  const checkLoanSettableToLiquidation = async (loanId, GracePeriod) => {
    try {
      const loanDetail = await getLoans(loanId);
      if (loanDetail == null) {
        console.log("Loan is invalid.");
        return false;
      }
      const lastPayTime = await getExpectedLastPaymentTimeLoan(loanId);
      if (lastPayTime == null) {
        console.log("Loan last payment time is invalid.");
        return false;
      }

      const nowSeconds = (Date.now() / 1000).toFixed(0);
      return loanDetail.status == LoanStatusInContract.DEFAULTED && nowSeconds > lastPayTime + (GracePeriod / 1000);

    } catch (ex) {
      console.log('checkLoanSettableToLiquidation: ', ex);
      return null;
    }

  }



  const setLoanDefaulted = async ({
    loanId, from, transactionHashCallback
  }) => {

    let _tribeOne = tribeone;
    if (!_tribeOne) {
      _tribeOne = await getTribeOne();
    }

    return new Promise((resolve, reject) => {

      _tribeOne.methods
        .setLoanDefaulted(
          loanId
        )
        .send({ from: from })
        .on("transactionHash", function (hash) {
          if (transactionHashCallback) {
            transactionHashCallback(hash, loanId);
          }
        })
        .on("error", function (error, receipt) {
          reject({ error, receipt });
        })
        .then((receipt) => {
          resolve(receipt);
        });

    })

  }


  const setLoanLiquidation = async ({
    loanId, from, transactionHashCallback
  }) => {

    let _tribeOne = tribeone;
    if (!_tribeOne) {
      _tribeOne = await getTribeOne();
    }

    return new Promise((resolve, reject) => {

      _tribeOne.methods
        .setLoanLiquidation(
          loanId
        )
        .send({ from: from })
        .on("transactionHash", function (hash) {
          if (transactionHashCallback) {
            transactionHashCallback(hash, loanId);
          }
        })
        .on("error", function (error, receipt) {
          reject({ error, receipt });
        })
        .then((receipt) => {
          resolve(receipt);
        });

    })

  }



  const relayNFT = async (
    _loanId,
    _agent,
    accepted,
    msgValue,
    from,
    transactionHashCallback,
  ) => {

    let _tribeOne = tribeone;
    if (!_tribeOne) {
      _tribeOne = await getTribeOne();
    }

    return new Promise((resolve, reject) => {
      let sendOption = { from };

      if (!accepted) {
        sendOption.value = msgValue;
      }

      _tribeOne.methods
        .relayNFT(
          _loanId, _agent, accepted
        )
        .send(sendOption)
        .on("transactionHash", function (hash) {
          if (transactionHashCallback) {
            transactionHashCallback(hash, _loanId);
          }
        })
        .on("error", function (error, receipt) {
          reject({ error, receipt });
        })
        .then((receipt) => {
          resolve(receipt);
        });

    })

  }


  const getHAKABalance = async () => {
    if (!address) {
      return null;
    }
    return await hakaCont.methods.balanceOf(address).call();
  }

  const withdrawNFT = (loanId) => {

    return new Promise(async (resolve, reject) => {

      let _tribeOne = tribeone;

      if (!tribeone) {
        _tribeOne = await getTribeOne();
      }

      _tribeOne.methods.withdrawNFT(loanId).send({ from: address })
        .on("error", (error, receipt) => {
          reject(error);
        })
        .then(receipt => {
          resolve(receipt);
        });
    });

  };


  useEffect(() => {
    (async () => {
      await initContract();
    })();
  }, []);

  return {
    web3,
    tribeone,
    sigwallet,
    assetManager,
    getLoanRules,
    getLoanAsset,
    getLoanNFTCount,
    getLoanNFTItem,
    totalDebt,
    getCollateralAsset,
    expectedLastPaymentTime,
    finalDebtAndPenalty,
    expectedNrOfPayments,
    withdrawNFT,
    getLoans,
    getHAKABalance,
    checkNFTOwner,
    getChainLinkETHUSD,
    getPayInstallmentAmount,
    isAdmin,
    approveLoan,
    getLoanIdFromCreateTransaction,
    relayNFT,
    setApprovalForAll,
    setLoanDefaulted,
    setLoanLiquidation,
    getExpectedLastPaymentTimeLoan,
    checkLoanSettableToDefaulted,
    checkLoanSettableToLiquidation,
    isApprovedForAll
  };
}

const contractContext = createContext({
  web3: undefined,
  tribeone: undefined,
  sigwallet: undefined,
  assetManager: undefined,
  getLoanRules: undefined,
  getLoanAsset: undefined,
  getLoanNFTCount: undefined,
  getLoanNFTItem: undefined,
  getCollateralAsset: undefined,
  totalDebt: undefined,
  expectedLastPaymentTime: undefined,
  finalDebtAndPenalty: undefined,
  expectedNrOfPayments: undefined,
  createLoan: undefined,
  withdrawNFT: undefined,
  getLoans: undefined,
  getHAKABalance: undefined,
  checkNFTOwner: undefined,
  getChainLinkETHUSD: undefined,
  getPayInstallmentAmount: undefined,
  isAdmin: undefined,
  approveLoan: undefined,
  getLoanIdFromCreateTransaction: undefined,
  relayNFT: undefined,
  setApprovalForAll: undefined,
  setLoanDefaulted: undefined,
  setLoanLiquidation: undefined,
  getExpectedLastPaymentTimeLoan: undefined,
  checkLoanSettableToDefaulted: undefined,
  checkLoanSettableToLiquidation: undefined,
  isApprovedForAll: undefined,
});

export const ContractProvider = ({ children }) => {
  const value = ContractConfig();
  return (
    <contractContext.Provider value={value}>
      {children}
    </contractContext.Provider>
  );
};

export const useContract = () => useContext(contractContext);
