import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selUser: undefined,
  curLoan: undefined,
  limit: 10,
};

export const rootSlice = createSlice({
  name: "root",
  initialState: initialState,
  reducers: {
    setSelUser: (state, action) => {
      state.selUser = action.payload;
    },   
    setCurLoan : (state, action)=>{
      state.curLoan = action.payload;
    },
    setPageLimit: (state, action)=>{
      state.limit = action.payload;
    }
  },
});

export const {
  setSelUser,
  setCurLoan,
  setPageLimit
} = rootSlice.actions;

export default rootSlice.reducer;
