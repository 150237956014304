import React from 'react';
import {
  Table, Button
} from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";
import { Shorter } from "./LoanTable";

const ApproveConfig = [
  {
    key: 'id',
    label: "#",
    render: (val) => {
      return <span>{val}</span>
    }
  },
  {
    key: 'create_loan_trx_hash',
    label: "LoanCreateTrx",
    render: (val, loan) => {
      return <span>
        <Shorter
          val={val}
          center={true}
          len={12}
          isLoanTest={loan.net_mode == "test"}
        />
      </span>
    }
  },
  {
    key: 'approve_loan_trx_hash',
    label: "LoanApproveTrx",
    render: (val, loan) => {
      return <span>
        <Shorter
          val={val}
          center={true}
          len={12}
          isLoanTest={loan.net_mode == "test"}
        />
      </span>
    }
  },
  {
    key: 'loanId',
    label: "LoanID",
    render: (val, loan) => {
      return <span>
        {val}
      </span>
    }
  },
  {
    key: 'tokenPrice',
    label: "TOKEN Price(ETH)",
    render: (val, loan) => {
      return <span>
        {val}
      </span>
    }
  },
  {
    key: 'agent',
    label: "Agent",
    render: (val, loan) => {
      return <span>
        <Shorter
          val={val}
          center={true}
          len={12}
          isLoanTest={loan.net_mode == "test"}
        />
      </span>
    }
  },
  {
    key: 'submitter',
    label: "Submitter(Signer)",
    render: (val, loan) => {
      return <span>
        <Shorter
          val={val}
          center={true}
          len={12}
          isLoanTest={loan.net_mode == "test"}
        />
      </span>
    }
  },
  {
    key: 'signer1',
    label: "Signer1",
    render: (val, loan) => {
        return <span>
            <Shorter
                val={val}
                center={true}
                len={12}
                isLoanTest={loan.net_mode == "test"}
            />
        </span>
    }
},
{
    key: 'signer2',
    label: "Signer2",
    render: (val, loan) => {
        return <span>
            <Shorter
                val={val}
                center={true}
                len={12}
                isLoanTest={loan.net_mode == "test"}
            />
        </span>
    }
},
  {
    key: 'callData',
    label: "CallData",
    render: (val, loan) => {
      return <>
        <textarea disabled style={{ }}>
          {val}
        </textarea>
        <div><span>"approveLoan" <br />( <br />{loan.approve.loanId},<br /> {loan.approve.tokenPrice},<br /> {loan.approve.agent}<br />)</span></div>
      </>
    }
  },
  {
    key: 'should_manual_approve',
    label: "Will Approve manually",
    render: (val, loan) => {
      return <span>
        {val ? 'True' : 'False'}
      </span>
    }
  },
  {
    key: 'isPicked',
    label: "IsPicked",
    render: (val, loan) => {
      return <span>
        {val ? 'True' : 'False'}
      </span>
    }
  },
  {
    key: 'txIndex',
    label: "Trx Index",
    render: (val, loan) => {
      return <span>
        {val}
      </span>
    }
  },
  {
    key: 'submitStatus',
    label: "Submit Status",
    render: (val, loan) => {
      return <span>
        {val}
      </span>
    }
  },
  {
    key: 'err_msg',
    label: "Error",
    render: (val, loan) => {
      return <textarea disabled style={{ }}>
        {val}
      </textarea>
    }
  },
  {
    key: 'submit_params',
    label: "Submit Params",
    render: (val, loan) => {
      return <textarea disabled style={{ }}>
        {val}
      </textarea>
    }
  },
  {
    key: 'created_at',
    label: "CreatedAt",
    render: (val, loan) => {
      return <span>
        {val}
      </span>
    }
  },
  {
    key: 'updated_at',
    label: "UpdatedAt",
    render: (val, loan) => {
      return <span>
        {val}
      </span>
    }
  },

]

const LoanApproveResultView = () => {
  const curLoan = useSelector((state) => state.root.curLoan);

  if (!curLoan || !curLoan.approve) {
    return <div>No Data</div>;
  }

  return <div>
    <Table style={{ width: '100%' }}>
      <tbody>
        {
          Object.keys(curLoan.approve).map(key => {
            const configItem = ApproveConfig.find(one => one.key == key);
            if (configItem) {
              return <tr>
                <td>{configItem.label}</td>
                <td>
                  {configItem.render(curLoan.approve[key], curLoan)}
                </td>
              </tr>
            } else {
              return <tr>
                <td>{key}</td>
                <td><strong>{curLoan.approve[key]}</strong></td>
              </tr>
            }

          })
        }
      </tbody>
    </Table>
    {/* <div>
      <Button
        variant="info"
        className="m-1"
        onClick={() => {
          
        }}
        disabled={}
      >
        Try Approve
      </Button>
    </div> */}
  </div>
}

export default LoanApproveResultView;