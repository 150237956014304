import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartPie,
    faCog,
    faHandHoldingUsd,
    faSignOutAlt,
    faTimes,
    faUsers,
    faHeart,
    faDollarSign,
    faHighlighter,
    faCalendar,
    faMailBulk,

    faRecordVinyl
} from "@fortawesome/free-solid-svg-icons";
import {
    Nav,
    Badge,
    Image,
    Button,
    Dropdown,
    Accordion,
    Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import Logo from "../assets/img/logo.png";

export default (props = {}) => {
    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const onCollapse = () => setShow(!show);

    const CollapsableNavItem = (props) => {
        const { eventKey, title, icon, children = null } = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

        return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Button
                        as={Nav.Link}
                        className="d-flex justify-content-between align-items-center"
                    >
                        <span>
                            <span className="sidebar-icon">
                                <FontAwesomeIcon icon={icon} />{" "}
                            </span>
                            <span className="sidebar-text">{title}</span>
                        </span>
                    </Accordion.Button>
                    <Accordion.Body className="multi-level">
                        <Nav className="flex-column">{children}</Nav>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };

    const NavItem = (props) => {
        const {
            title,
            link,
            external,
            target,
            icon,
            image,
            badgeText,
            badgeBg = "secondary",
            badgeColor = "primary",
        } = props;
        const classNames = badgeText
            ? "d-flex justify-content-start align-items-center justify-content-between"
            : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? { href: link } : { as: Link, to: link };

        return (
            <Nav.Item
                className={navItemClassName}
                onClick={() => setShow(false)}
            >
                <Nav.Link {...linkProps} target={target} className={classNames}>
                    <span>
                        {icon ? (
                            <span className="sidebar-icon">
                                <FontAwesomeIcon icon={icon} />{" "}
                            </span>
                        ) : null}
                        {image ? (
                            <Image
                                src={image}
                                width={20}
                                height={20}
                                className="sidebar-icon svg-icon"
                            />
                        ) : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge
                            pill
                            bg={badgeBg}
                            text={badgeColor}
                            className="badge-md notification-count ms-2"
                        >
                            {badgeText}
                        </Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    return (
        <>
            <Navbar
                expand={false}
                collapseOnSelect
                variant="dark"
                className="navbar-theme-primary px-4 d-md-none"
            >
                <Navbar.Brand
                    className="me-lg-5"
                    as={Link}
                    to={Routes.DashboardOverview.path}
                >
                    <img src={Logo} style={{ width: 50, height: 50 }} />
                    <h2>Tribeone</h2>
                </Navbar.Brand>
                <Navbar.Toggle
                    as={Button}
                    aria-controls="main-navbar"
                    onClick={onCollapse}
                >
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition
                timeout={300}
                in={show}
                classNames="sidebar-transition"
            >
                <SimpleBar
                    className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
                >
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar lg-avatar me-4">
                                    <Image
                                        src={ProfilePicture}
                                        className="card-img-top rounded-circle border-white"
                                    />
                                </div>
                                <div className="d-block">
                                    <h6>Hi, Jane</h6>
                                    <Button
                                        as={Link}
                                        variant="secondary"
                                        size="xs"
                                        to={Routes.Signin.path}
                                        className="text-dark"
                                    >
                                        <FontAwesomeIcon
                                            icon={faSignOutAlt}
                                            className="me-2"
                                        />{" "}
                                        Sign Out
                                    </Button>
                                </div>
                            </div>
                            <Nav.Link
                                className="collapse-close d-md-none"
                                onClick={onCollapse}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <Nav className="flex-column pt-3 pt-md-0">
                            <Navbar.Brand
                                className="me-lg-5 my-3"
                                as={Link}
                                to={Routes.DashboardOverview.path}
                            >
                                <img
                                    src={Logo}
                                    style={{ width: 50, height: 50 }}
                                />
                                <h3 style={{ display: "inline", marginTop: 5 }}>
                                    {" "}
                                    Tribeone
                                </h3>
                            </Navbar.Brand>
                            <NavItem
                                title="Overview"
                                link={Routes.DashboardOverview.path}
                                icon={faChartPie}
                            />
                            <NavItem
                                title="Users"
                                icon={faUsers}
                                link={Routes.Users.path}
                            />
                            <NavItem
                                title="Loans"
                                icon={faDollarSign}
                                link={Routes.Loans.path}
                            />
                            <NavItem
                                title="Favorites"
                                icon={faHeart}
                                link={Routes.Favorites.path}
                            />
                            {/* <NavItem
                              title="Survey"
                              icon={faQuestionCircle}
                              link={Routes.Survey.path}
                            /> */}
                            {/* <NavItem title="NFTs" icon={faGem} link={Routes.NFT.path} /> */}

                            <Dropdown.Divider className="my-1 border-indigo" />

                            <NavItem
                                title="Transactions"
                                icon={faHandHoldingUsd}
                                link={Routes.Transactions.path}
                            />
                            <NavItem
                                title="Events"
                                icon={faCalendar}
                                link={Routes.events.path}
                            />
                            <NavItem
                                title="Logs"
                                icon={faRecordVinyl}
                                link={Routes.Logs.path}
                            />
                            <NavItem
                                title="Mails"
                                icon={faMailBulk}
                                link={Routes.Mails.path}
                            />

                            <Dropdown.Divider className="my-1 border-indigo" />

                            <NavItem
                                title="Settings"
                                icon={faCog}
                                link={Routes.Settings.path}
                            />
                            <Dropdown.Divider className="my-3 border-indigo" />

                            {process.env.NODE_ENV == "development" && (
                                <>
                                    <NavItem title="Docs" link={""} />

                                    <Dropdown.Divider className="my-3 border-indigo" />
                                    <NavItem
                                        title="DocsDownload"
                                        icon={faCog}
                                        link={Routes.DocsDownload.path}
                                    />
                                    <NavItem
                                        title="DocsQuickStart"
                                        icon={faCog}
                                        link={Routes.DocsQuickStart.path}
                                    />
                                    <NavItem
                                        title="DocsFolderStructure"
                                        icon={faCog}
                                        link={Routes.DocsFolderStructure.path}
                                    />
                                  
                               
                                    <Dropdown.Divider className="my-3 border-indigo" />

                                    <NavItem title="Components" link={""} />

                                    <Dropdown.Divider className="my-3 border-indigo" />

                                    <NavItem
                                        title="Accordions"
                                        icon={faCog}
                                        link={Routes.Accordions.path}
                                    />
                                    <NavItem
                                        title="Alerts"
                                        icon={faCog}
                                        link={Routes.Alerts.path}
                                    />
                                    <NavItem
                                        title="Badges"
                                        icon={faCog}
                                        link={Routes.Badges.path}
                                    />
                                   
                                    <NavItem
                                        title="Breadcrumbs"
                                        icon={faCog}
                                        link={Routes.Breadcrumbs.path}
                                    />
                                    <NavItem
                                        title="Buttons"
                                        icon={faCog}
                                        link={Routes.Buttons.path}
                                    />
                                    <NavItem
                                        title="Forms"
                                        icon={faCog}
                                        link={Routes.Forms.path}
                                    />
                                    <NavItem
                                        title="Modals"
                                        icon={faCog}
                                        link={Routes.Modals.path}
                                    />
                                    <NavItem
                                        title="Navs"
                                        icon={faCog}
                                        link={Routes.Navs.path}
                                    />
                                    <NavItem
                                        title="Navbars"
                                        icon={faCog}
                                        link={Routes.Navbars.path}
                                    />
                                    <NavItem
                                        title="Pagination"
                                        icon={faCog}
                                        link={Routes.Pagination.path}
                                    />
                                    <NavItem
                                        title="Popovers"
                                        icon={faCog}
                                        link={Routes.Popovers.path}
                                    />
                                    <NavItem
                                        title="Progress"
                                        icon={faCog}
                                        link={Routes.Progress.path}
                                    />
                                    <NavItem
                                        title="Tables"
                                        icon={faCog}
                                        link={Routes.Tables.path}
                                    />
                                    <NavItem
                                        title="Tabs"
                                        icon={faCog}
                                        link={Routes.Tabs.path}
                                    />
                                    <NavItem
                                        title="Tooltips"
                                        icon={faCog}
                                        link={Routes.Tooltips.path}
                                    />
                                    <NavItem
                                        title="Toasts"
                                        icon={faCog}
                                        link={Routes.Toasts.path}
                                    />
                                  
                                </>
                            )}
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};
