import { IsManaContract } from "../config/ManaETH";

export const Utils = {
  shorten: (str, centerRemove = false, len = 8) => {
    if (!str ||  str.length <= len || len == -1) {
      return str;
    }
    if (centerRemove) {
      const firstLen = Math.floor(len / 2);
      const endLen = len - firstLen;

      return (
        str.toString().slice(0, firstLen) +
        "..." +
        str.toString().slice(-1 * endLen)
      );
    } else {
      return str.toString().slice(0, len) + "...";
    }
  },

  getOpenseaNFTUrl:(tokenId, tokenAddr)=>{
    const prefix = process.env.REACT_APP_NETWORK == 'test' ? 'testnets.' : '';
    if (IsManaContract(tokenAddr)) {
      return `https://market.decentraland.org/contracts/${(''+tokenAddr).toLowerCase()}/tokens/${tokenId}`;  
    } else{
      return `https://${prefix}opensea.io/assets/${tokenAddr}/${tokenId}`;
    }
  },

  
};
