import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";

import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
import { Provider } from "react-redux";
import store from "./state/store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import { AuthUserProvider } from "./context/AuthContext";
import { ContractProvider } from "./context/ContractContext";

let persistor = persistStore(store);

console.log('process.env.NODE_ENV=>', process.env.NODE_ENV)
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthUserProvider>
        <ContractProvider>
          <SnackbarProvider maxSnack={3}>
            <HashRouter>
              <ScrollToTop />
              <HomePage />
            </HashRouter>
          </SnackbarProvider>
        </ContractProvider>
      </AuthUserProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
