
export const Routes = {
    // pages
    // Presentation: { path: "/" },
    DashboardOverview: { path: "/" },
    
    Transactions: { path: "/admin/transactions" },
    events: { path: "/admin/events" },
    Logs: { path: "/admin/logs" },
    Users: { path: "/admin/users" },
    UsersEdit: { path: "/admin/users/edit" },
    Loans: { path: "/admin/loans" },
    LoansDetail: { path: "/admin/loans/detail" },
    Favorites: { path: "/admin/favorites" },
    FavoritesDetail: { path: "/admin/favorites/detail" },
    Mails: { path: "/admin/mails" },
    // Survey: { path: "/admin/survey" },
    
    NFT: { path: "/admin/nfts" },    
    NFTDetail: { path: "/admin/nfts/detail" },    
    Settings: { path: "/admin/settings" },

    Upgrade: { path: "/upgrade" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/account/forgot-password" },
    ResetPassword: { path: "/account/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // docs
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    
    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
};