import React, { useState } from 'react';
import {
    Table, Button
} from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";
import { Shorter } from "./LoanTable";
import { useAuth } from '../../context/AuthContext';
import Api from '../../apis/Api';
import ColorLoader from "../../assets/img/color-loader.gif";

const RelayConfig = [
    {
        key: 'id',
        label: "#",
        render: (val) => {
            return <span>{val}</span>
        }
    },
    {
        key: 'approve_loan_trx_hash',
        label: "ApproveTrx",
        render: (val, loan) => {
            return <span>
                <Shorter
                    val={val}
                    center={true}
                    len={12}
                    isLoanTest={loan.net_mode == "test"}
                />
            </span>
        }
    },
    {
        key: 'relay_loan_trx_hash',
        label: "RelayTrx",
        render: (val, loan) => {
            return <span>
                <Shorter
                    val={val}
                    center={true}
                    len={12}
                    isLoanTest={loan.net_mode == "test"}
                />
            </span>
        }
    },
    {
        key: 'buy_trx_hash',
        label: "Buy Trx hash",
        render: (val, loan) => {
            return <span>
                <Shorter
                    val={val}
                    center={true}
                    len={12}
                    isLoanTest={loan.net_mode == "test"}
                />
            </span>
        }
    },
    {
        key: 'loanId',
        label: "LoanID",
        render: (val, loan) => {
            return <span>
                {val}
            </span>
        }
    },
    {
        key: 'agent',
        label: "Agent",
        render: (val, loan) => {
            return <span>
                <Shorter
                    val={val}
                    center={true}
                    len={12}
                    isLoanTest={loan.net_mode == "test"}
                />
            </span>
        }
    },
    {
        key: 'submitter',
        label: "Submitter(Signer)",
        render: (val, loan) => {
            return <span>
                <Shorter
                    val={val}
                    center={true}
                    len={12}
                    isLoanTest={loan.net_mode == "test"}
                />
            </span>
        }
    },
    {
        key: 'signer1',
        label: "Signer1",
        render: (val, loan) => {
            return <span>
                <Shorter
                    val={val}
                    center={true}
                    len={12}
                    isLoanTest={loan.net_mode == "test"}
                />
            </span>
        }
    },
    {
        key: 'signer2',
        label: "Signer2",
        render: (val, loan) => {
            return <span>
                <Shorter
                    val={val}
                    center={true}
                    len={12}
                    isLoanTest={loan.net_mode == "test"}
                />
            </span>
        }
    },
    
    {
        key: 'purchased',
        label: "Purchased",
        render: (val, loan) => {
            return <span>
                {val == true ? 'TRUE' : 'FALSE'}
            </span>
        }
    },
    {
        key: 'msgValue',
        label: "Msg Value",
        render: (val, loan) => {
            return <span>
                {val}
            </span>
        }
    },
    {
        key: 'submitStatus',
        label: "Submit Status",
        render: (val, loan) => {
            return <span>
                {val}
            </span>
        }
    },
    {
        key: 'success_nft_approval',
        label: "Successed NFT Approval",
        render: (val, loan) => {
            return <span>
                {val.toString()}
            </span>
        }
    },
    {
        key: 'err_msg_purchase_nft',
        label: "Error Buy NFT",
        render: (val, loan) => {
            return <textarea id="err_msg_purchase_nft" disabled style={{ }}>
                {val}
            </textarea>
        }
    },
    {
        key: 'submit_params',
        label: "Submit Params",
        render: (val, loan) => {
            return <textarea id="submit_params_relay" disabled style={{ }}>
                {val}
            </textarea>
        }
    },

    {
        key: 'err_msg',
        label: "Error",
        render: (val, loan) => {
            return <textarea id="err_msg" disabled style={{ }}>
                {val}
            </textarea>
        }
    },

    {
        key: 'isPicked',
        label: "IsPicked",
        render: (val, loan) => {
            return <span>
                {val ? 'True' : 'False'}
            </span>
        }
    },
    {
        key: 'callData',
        label: "CallData",
        render: (val, loan) => {
            return <><textarea disabled style={{  }}>
                {val}
            </textarea>
                <div><span>"relayNFT" <br />(<br />{loan.relay.loanId}, <br />{loan.relay.agent},<br /> {loan.relay.purchased.toString()}<br />)</span></div>
            </>
        }
    },
    {
        key: 'txIndex',
        label: "Trx Index",
        render: (val, loan) => {
            return <span>
                {val}
            </span>
        }
    },


    {
        key: 'created_at',
        label: "CreatedAt",
        render: (val, loan) => {
            return <span>
                {val}
            </span>
        }
    },
    {
        key: 'updated_at',
        label: "UpdatedAt",
        render: (val, loan) => {
            return <span>
                {val}
            </span>
        }
    },



]



const LoanRelayResultView = () => {
    const curLoan = useSelector((state) => state.root.curLoan);
    const { token } = useAuth();
    const [isBusy, setIsBusy] = useState(false);

    // const relayTry = async () => {
    //     if (curLoan.relay.submitStatus == "Failed") {
    //         alert(curLoan.relay.loanId);
    //         const res = await Api.relayLoanForce(curLoan.relay.loanId, token);
    //         if (res.data) {
    //             if (res.data.status == 400) {
    //                 alert('Invalid loan id.');
    //             } else if (res.data.status == 503) {
    //                 alert('Server is busy to process relay. try after a moment.');
    //             } else if (res.data.status == 200) {
    //                 alert('Request loan relay successfully');
    //             }
    //         }
    //     } else {
    //         alert('It is impossible to relay now.');
    //     }
    // }

    if (!curLoan || !curLoan.relay) {
        return <div>No Data</div>;
    }

    return <div>
        <Table style={{ width: '100%' }} width="100%">

            <tbody width="100%">
                {
                    Object.keys(curLoan.relay).map(key => {
                        const configItem = RelayConfig.find(one => one.key == key);
                        if (configItem) {
                            return <tr>
                                <td>{configItem.label}</td>
                                <td>
                                    {configItem.render(curLoan.relay[key], curLoan)}
                                </td>
                            </tr>
                        } else {
                            return <tr>
                                <td>{key}</td>
                                <td><strong>{curLoan.relay[key]}</strong></td>
                            </tr>
                        }
                    })
                }
            </tbody>
        </Table>
        {/* <div align="right">
            {

                isBusy ?
                    <img src={ColorLoader} style={{ width: 40, height: 40 }} /> :
                    <Button
                        variant="info"
                        className="m-1"
                        onClick={() => {
                            relayTry();
                        }}
                        disabled={curLoan.relay.submitStatus != "Failed"}
                    >
                        Try Relay
                    </Button>
            }

        </div> */}
    </div>
}

export default LoanRelayResultView;