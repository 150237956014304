// export const TRIBEONE_ADDRESS =  process.env.REACT_APP_NETWORK === 'live' ?  "0x71cb5ce3b51886aa7d3423f774890a78a8245aef": "0x0a1c88a52252200eb43F5338d4e43a39a870944C"; // mainnet
export const TRIBEONE_ADDRESS =
  process.env.REACT_APP_NETWORK === "live"
    ? "0xd94C3456E27483Bba74887cCB09d5dC16cf33028"
    : "0x0a1c88a52252200eb43F5338d4e43a39a870944C"; // rinkeby
export const TRIBEONE_ABI = [
  {
    inputs: [
      { internalType: "address", name: "_salesManager", type: "address" },
      { internalType: "address", name: "_feeTo", type: "address" },
      { internalType: "address", name: "_feeCurrency", type: "address" },
      { internalType: "address", name: "_multiSigWallet", type: "address" },
      { internalType: "address", name: "_assetManager", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_setter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "_admin",
        type: "address",
      },
    ],
    name: "AddAdmin",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_currency",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "InstallmentPaid",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      { indexed: true, internalType: "address", name: "_to", type: "address" },
      {
        indexed: false,
        internalType: "address",
        name: "_fundCurreny",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_fundAmount",
        type: "uint256",
      },
    ],
    name: "LoanApproved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_sender",
        type: "address",
      },
    ],
    name: "LoanCanceled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "loanId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "nftAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "nftTokenId",
        type: "uint256",
      },
      { indexed: false, internalType: "bool", name: "isERC721", type: "bool" },
    ],
    name: "LoanCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loandId",
        type: "uint256",
      },
    ],
    name: "LoanDefaulted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_salesManager",
        type: "address",
      },
    ],
    name: "LoanLiquidation",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_soldAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_finalDebt",
        type: "uint256",
      },
    ],
    name: "LoanPostLiquidation",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_agent",
        type: "address",
      },
    ],
    name: "LoanRejected",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "_adapter",
        type: "address",
      },
    ],
    name: "LoanRented",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_adapter",
        type: "address",
      },
    ],
    name: "LoanWithdrawFromRent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "_sender",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "_accepted", type: "bool" },
    ],
    name: "NFTRelayed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      { indexed: false, internalType: "address", name: "_to", type: "address" },
    ],
    name: "NFTWithdrew",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "_setter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "_admin",
        type: "address",
      },
    ],
    name: "RemoveAdmin",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "_loanId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "RestWithdrew",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_feeTo",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_lateFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "_penaltyFee",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_salesManager",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "_assetManager",
        type: "address",
      },
    ],
    name: "SettingsUpdate",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "SuperOwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "GRACE_PERIOD",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MAX_SLIPPAGE",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TENOR_UNIT",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_admin", type: "address" }],
    name: "addAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_adapter", type: "address" }],
    name: "addRentAdapter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_loanId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
      { internalType: "address", name: "_agent", type: "address" },
    ],
    name: "approveLoan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "assetManager",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "cancelLoan",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint16[]", name: "_loanRules", type: "uint16[]" },
      { internalType: "address[]", name: "_currencies", type: "address[]" },
      { internalType: "address", name: "nftAddress", type: "address" },
      { internalType: "bool", name: "isERC721", type: "bool" },
      { internalType: "uint256[]", name: "_amounts", type: "uint256[]" },
      { internalType: "uint256", name: "nftTokenId", type: "uint256" },
    ],
    name: "createLoan",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "currentDebt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "expectedLastPaymentTime",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeCurrency",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeTo",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "finalDebtAndPenalty",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "loanId", type: "uint256" },
      { internalType: "bytes", name: "withdrawCallData", type: "bytes" },
    ],
    name: "forceWithdrawCall",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "getBackFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "getCollateralAsset",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "address", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "getLoanAsset",
    outputs: [
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "address", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "getLoanNFTItem",
    outputs: [
      {
        components: [
          { internalType: "address", name: "nftAddress", type: "address" },
          { internalType: "bool", name: "isERC721", type: "bool" },
          { internalType: "uint256", name: "nftId", type: "uint256" },
        ],
        internalType: "struct DataTypes.NFTItem",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "getLoanRent",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "getLoans",
    outputs: [
      {
        components: [
          { internalType: "uint256", name: "fundAmount", type: "uint256" },
          { internalType: "uint256", name: "paidAmount", type: "uint256" },
          { internalType: "uint256", name: "loanStart", type: "uint256" },
          { internalType: "uint256", name: "postTime", type: "uint256" },
          { internalType: "uint256", name: "restAmount", type: "uint256" },
          { internalType: "address", name: "borrower", type: "address" },
          { internalType: "uint8", name: "nrOfPenalty", type: "uint8" },
          { internalType: "uint8", name: "passedTenors", type: "uint8" },
          {
            components: [
              { internalType: "uint256", name: "amount", type: "uint256" },
              { internalType: "address", name: "currency", type: "address" },
            ],
            internalType: "struct DataTypes.Asset",
            name: "loanAsset",
            type: "tuple",
          },
          {
            components: [
              { internalType: "uint256", name: "amount", type: "uint256" },
              { internalType: "address", name: "currency", type: "address" },
            ],
            internalType: "struct DataTypes.Asset",
            name: "collateralAsset",
            type: "tuple",
          },
          {
            internalType: "enum DataTypes.Status",
            name: "status",
            type: "uint8",
          },
          {
            components: [
              { internalType: "uint16", name: "tenor", type: "uint16" },
              { internalType: "uint16", name: "LTV", type: "uint16" },
              { internalType: "uint16", name: "interest", type: "uint16" },
            ],
            internalType: "struct DataTypes.LoanRules",
            name: "loanRules",
            type: "tuple",
          },
          {
            components: [
              { internalType: "address", name: "nftAddress", type: "address" },
              { internalType: "bool", name: "isERC721", type: "bool" },
              { internalType: "uint256", name: "nftId", type: "uint256" },
            ],
            internalType: "struct DataTypes.NFTItem",
            name: "nftItem",
            type: "tuple",
          },
        ],
        internalType: "struct DataTypes.Loan",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "grantAllowanceForAssetManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_admin", type: "address" }],
    name: "isAdmin",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "loanId", type: "uint256" },
      { internalType: "address", name: "user", type: "address" },
    ],
    name: "isAvailableRentalAction",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "lateFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "loanId", type: "uint256" },
      { internalType: "address", name: "borrower", type: "address" },
    ],
    name: "listNFTForRent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "loanIds",
    outputs: [{ internalType: "uint256", name: "_value", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256[]", name: "_loanIds", type: "uint256[]" },
      { internalType: "address", name: "_currency", type: "address" },
    ],
    name: "lockRestAmount",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256[]", name: "", type: "uint256[]" },
      { internalType: "uint256[]", name: "", type: "uint256[]" },
      { internalType: "bytes", name: "", type: "bytes" },
    ],
    name: "onERC1155BatchReceived",
    outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "bytes", name: "", type: "bytes" },
    ],
    name: "onERC1155Received",
    outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "", type: "address" },
      { internalType: "address", name: "", type: "address" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "bytes", name: "", type: "bytes" },
    ],
    name: "onERC721Received",
    outputs: [{ internalType: "bytes4", name: "", type: "bytes4" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_loanId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "payInstallment",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "penaltyFee",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_loanId", type: "uint256" },
      { internalType: "uint256", name: "_amount", type: "uint256" },
    ],
    name: "postLiquidation",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_loanId", type: "uint256" },
      { internalType: "address", name: "_agent", type: "address" },
      { internalType: "bool", name: "_accepted", type: "bool" },
    ],
    name: "relayNFT",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_admin", type: "address" }],
    name: "removeAdmin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_adapter", type: "address" }],
    name: "removeRentAdapter",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_token", type: "address" }],
    name: "revokeAllowanceForAssetManager",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "salesManager",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "setLoanDefaulted",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "setLoanLiquidation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "_feeTo", type: "address" },
      { internalType: "uint256", name: "_lateFee", type: "uint256" },
      { internalType: "uint256", name: "_penaltyFee", type: "uint256" },
      { internalType: "address", name: "_salesManager", type: "address" },
      { internalType: "address", name: "_assetManager", type: "address" },
    ],
    name: "setSettings",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "superOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "totalDebt",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "newSuperOwner", type: "address" },
    ],
    name: "transferSuperOwnerShip",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_loanId", type: "uint256" }],
    name: "withdrawNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "loanId", type: "uint256" }],
    name: "withdrawNFTFromRent",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];
