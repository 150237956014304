import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {

  Nav,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const UsersTable = ({
  rows = [],
  total,
  page = 1,
  limit = 10,
  onChangePage,
  onDetails,
  onEdit,
  onRemove,
}) => {
  const UserRow = ({
    index,
    id,
    email,
    username,
    wallet,
    code,
    role,
    isActive,
    created_at,
    updated_at,
    onDetails,
    onEdit,
    onRemove,
    page
  }) => {

    const statusVariant = isActive ? "success" : "danger";
    const status = isActive ? "Activated" : "Inactivated";

    const rowNo = (page - 1) * limit + index + 1;
    return (
      <tr>
        <td title={id}>
          <Card.Link as={Link} className="fw-normal">
            {rowNo}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{email}</span>
        </td>
        <td>
          <span className="fw-normal">{username}</span>
        </td>
        <td>
          <span className="fw-normal">{role}</span>
        </td>
        <td>
          <span className="fw-normal">{wallet}</span>
        </td>
        <td>
          <span className={`fw-normal text-${statusVariant}`}>{status}</span>
        </td>
        <td>
          <span className="fw-normal">{moment(created_at).format("MM/DD/YYYY") }</span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  onDetails(id);
                }}
              >
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  const totalPages = Math.ceil(total / limit);
  const numberOfRows = rows ? rows.length : 0;

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Sr.No</th>
              <th className="border-bottom">Email</th>
              <th className="border-bottom">User Name</th>
              <th className="border-bottom">Role</th>
              <th className="border-bottom">Wallet</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Created At</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((user, index) => (
              <UserRow
                index={index}
                key={`user-${user.id}`}
                {...user}
                onDetails={() => onDetails(user)}
                onRemove={() => onRemove(user)}
                onEdit={() => onEdit(user)}
                page={page}
                limit={limit}
              />
            ))}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.First
                onClick={() => {
                  onChangePage(1);
                }}
              >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
              </Pagination.First>
              <Pagination.Prev
                onClick={() => {
                  onChangePage(page - 1 > 0 ? page - 1 : 1);
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </Pagination.Prev>
              <Pagination.Item>{page}</Pagination.Item>
              <Pagination.Next
                onClick={() => {
                  onChangePage(page + 1 <= totalPages ? page + 1 : totalPages);
                }}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </Pagination.Next>
              <Pagination.Last
                onClick={() => {
                  onChangePage(totalPages);
                }}
              >
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </Pagination.Last>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{numberOfRows}</b> out of <b>{total}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};
