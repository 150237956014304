import { HttpDelete, HttpGet, HttpPost, HttpPatch, HttpPut, HOST } from "../Api";

const MailApi = {
  getMailTypes: async () => {
    const url = `${HOST}mail/mail_types`;
    const res = await HttpGet(url);
    return res;
  },

  sendTestEmail: async(mailType, receiver_email, params, token)=>{
    
    const url = `${HOST}mail/test/sendEmail`;
    const res = await HttpPost(url, null, { mailType, receiver_email, ...params});
    
    return res;
  }
};



export default MailApi;