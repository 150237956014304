import React, { createContext, useContext} from "react";
import useAuthValue from '../hooks/useAuthValue';


const authUserContext = createContext({
  user: null,
  loading: true,
  token: undefined,
  signIn: undefined,
  signOut: undefined,
  storeToken: undefined,
  storeUser: undefined,
  tokenLoaded: false
});


export const AuthUserProvider = ({ children }) =>{
  const value = useAuthValue()

  return (
    <authUserContext.Provider value={value}>
      {children}</authUserContext.Provider>
  );
}


export const useAuth = () => useContext(authUserContext);
