import React from "react";
import { Button, Card, Table } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import BigNumber from "bignumber.js";
import { Shorter } from "../../features/loans/LoanTable";
import moment from "moment";
import { PaginationFooter } from "../../features/favorites/FavTable";

export const EventsTable = ({
  rows = [],
  total,
  page = 1,
  limit = 10,
  loan,
  onChangePage,
  onChkChange,
  onRemove,
  onRestore,
  isRestoring
}) => {
  const TableRow = (props) => {
    const {
      rowIndex,
      id,
      lastBlockNumber,
      blockHash,
      lastTransactionHash,
      event_name,
      network,
      created_at,
      updated_at,
      onChkChange,
      onRestoreRow,
      isRestoring

    } = props;

    const handleRestore = ()=>{
      onRestoreRow(lastBlockNumber, lastTransactionHash)
    }

    return (
      <tr>
        <td>
          <input
            id={"chk_event_" + id}
            className={"chk_event_item"}
            type="checkbox"
            onChange={onChkChange}
          ></input>
        </td>
        <td>
          <Card.Link as={Link} className="fw-normal">
            {rowIndex}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{id}</span>
        </td>
        <td>
          <span className="fw-normal">{event_name}</span>
        </td>
        <td>
          <span className="fw-normal">{lastBlockNumber}</span>
        </td>
        <td>
          <span className="fw-normal">{blockHash}</span>
        </td>
        <td>
          <span className="fw-normal">{lastTransactionHash}</span>
        </td>
        <td>
          <span className="fw-normal">{network}</span>
        </td>
        <td>
          <span className="fw-normal">
            {moment(created_at).format("YYYY-MM-DDTHH:mm:ss")}
          </span>
        </td>
        <td>
          {
            event_name == 'LoanCreated' && <Button  variant="outline-danger" size={'sm'} disabled={isRestoring} onClick={handleRestore}>Restore Loan</Button>
          }          
          
        </td>
      </tr>
    );
  };

  const totalPages = Math.ceil(total / limit);
  const numberOfRows = rows ? rows.length : 0;

  const onAllCheck = (e) => {
    let checkboxes = document.getElementsByClassName("chk_event_item");
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = e.target.checked;
    }
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">
                <input
                  id="all_chk"
                  type="checkbox"
                  onChange={onAllCheck}
                ></input>
              </th>
              <th className="border-bottom">Sr.No</th>
              <th className="border-bottom">Id</th>
              <th className="border-bottom">Event Name</th>
              <th className="border-bottom">Last Block Number</th>
              <th className="border-bottom">BlockHash</th>
              <th className="border-bottom">LastTransactionHash</th>
              <th className="border-bottom">Network</th>
              <th className="border-bottom">Created At</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((t, rowIndex) => (
              <TableRow
                key={`transaction-${t.id}`}
                {...t}
                rowIndex={rowIndex + 1}
                onChkChange={onChkChange}
                onRestoreRow={onRestore}
                isRestoring={isRestoring}
              />
            ))}
          </tbody>
        </Table>
        <PaginationFooter
          page={page}
          onChangePage={onChangePage}
          totalPages={totalPages}
          numberOfRows={numberOfRows}
          total={total}
        />
      </Card.Body>
    </Card>
  );
};
