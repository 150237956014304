import React, { useState } from "react";
import { Col, Row, Button, Form, Card } from "@themesberg/react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router";
import { Routes } from "../../routes";
import Api from "../../apis/Api";
import { useSnackbar } from "notistack";
import Code from '../../components/Code';

let timerForStatus = undefined;

export default function () {
  const [mainNode, setMainNode] = useState();
  const [childNodes, setChildNodes] = useState([]);
  const [logFilter, setLogFilter] = useState("");

  React.useEffect(() => {
    timerCallback();
    return () => {
      if (timerForStatus) {
        clearTimeout(timerForStatus);
      }
    };
  }, []);

  const getLogFilter = async () => {
    try {
      const res = await Api.getLogFiler();
      console.log(res);
      if (res && res.status == 200) {
        setLogFilter(res.data);
      } else {
        setLogFilter(undefined);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const timerCallback = () => {
    if (timerForStatus != undefined) {
      clearTimeout(timerForStatus);
    }
    timerForStatus = setTimeout(async () => {
      await getLogFilter();
      timerCallback();
    }, 5000);
  };

  return (
    <Row>
      <Col xs={12} xl={8}>
        <ConfInfoForm />
      </Col>

      <Col xs={12} xl={8}>
      LogFilter : <br />
        <Code code={JSON.stringify(logFilter, null, 4)} language="json">
        </Code>
        {/* <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            
          </Card.Body>
        </Card> */}
      </Col>
    </Row>
  );
}

export const ConfInfoForm = () => {
  const { token } = useAuth();
  const [networkTest, setNetWorkTest] = useState(0);
  const [cronTest, setCronTest] = useState(0);
  const [secsPerDay, setSecsPerDay] = useState(20);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    if (!token) {
      history.replace(Routes.Signin.path);
      return null;
    }
    const res = await Api.getConf(token);

    if (res.data && res.data.status == 200) {
      const data = res.data.data;
      setNetWorkTest(data.network_test == true ? 1 : 0);
      setCronTest(data.cron_test == true ? 1 : 0);
      setSecsPerDay(parseInt(data.secs_per_day_cron));
    } else {
    }

    const resMail = await Api.getMailConf(token);
    console.log("resMail:", resMail);

    if (res.data && res.data.status == 200) {
    }
  };
  React.useEffect(() => {
    loadData();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      history.replace(Routes.Signin.path);
      return null;
    }

    try {
      const res = await Api.updateConf({
        cron_test: cronTest == 1,
        network_test: networkTest == 1,
        secs_per_day_cron: secsPerDay,
        token,
      });
      if (res.data && res.data.status == 200) {
        enqueueSnackbar("Successfully updated.", { variant: "success" });
      } else {
        enqueueSnackbar(res.data.error, { variant: "error" });
      }
    } catch (ex) {
      enqueueSnackbar(ex.message, { variant: "error" });
    }
  };


  const onHealth = async()=>{
    setLoading(true)
    const res = await Api.getHealth();
    console.log(res)
    alert(JSON.stringify(res.data, null, 2))
    setLoading(false)
  }

  return (<>
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Config information</h5>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="network_test">
                <Form.Label>Network Mode</Form.Label>
                <Form.Select
                  // defaultValue={networkTest}
                  value={networkTest}
                  onChange={(e) => setNetWorkTest(e.target.value)}
                >
                  <option value={1}>Test</option>
                  <option value={0}>Live</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="cron_test">
                <Form.Label>Cron Mode</Form.Label>
                <Form.Select
                  // defaultValue={cronTest}
                  value={cronTest.toString()}
                  onChange={(e) => setCronTest(e.target.value)}
                >
                  <option value={1}>Test</option>
                  <option value={0}>Live</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="secs_per_day_cron">
                <Form.Label>Seconds / 1Day (Cron Test) </Form.Label>
                <Form.Control
                  required
                  type="number"
                  min={15}
                  placeholder="Seconds / 1Day (Cron Test)"
                  value={secsPerDay}
                  onChange={(e) => setSecsPerDay(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-3">
            <Button variant="primary" type="submit">
              Save All
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Check Server</h5>
        
          <div className="mt-3">
            <Button variant="primary" onClick={onHealth}>
              Health Check
            </Button>
          </div>
            
      </Card.Body>
    </Card>
    </>
  );
};

export const NodeStatus = ({ statusRes, isMain }) => {
  // "status": 200,
  // "is_processing": false,
  // "nodeNumber": "1"
  if (!statusRes) {
    return <span> -- </span>;
  }
  const node = statusRes.node;

  if (isMain) {
    const status = statusRes.status;
    const is_processing = statusRes.is_processing == true ? "Busy" : "Idle";
    const nodeNumber = statusRes.nodeNumber;

    return (
      <div style={{ margin: "0 10px 0 3px" }}>
        {node} - {status} - {is_processing}
      </div>
    );
  }
  if (statusRes.res) {
    const status = statusRes.res.status;
    const is_processing = statusRes.res.is_processing == true ? "Busy" : "Idle";
    const nodeNumber = statusRes.res.nodeNumber;

    return (
      <div style={{ margin: "0 10px 0 3px" }}>
        {node} - {status} - {is_processing}
      </div>
    );
  } else {
    return <div style={{ margin: "0 10px 0 3px" }}>{node} - error</div>;
  }
};
