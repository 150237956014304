import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowDown,
    faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Utils } from "../../utils/utils";
import { useSnackbar } from "notistack";
import BigNumber from "bignumber.js";
import { faEllipsisH, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
    Nav,
    Card,
    Button,
    Table,
    Dropdown,
    Pagination,
    ButtonGroup,
    Badge,
} from "@themesberg/react-bootstrap";
import { BadgeClass } from "./LoanDetail";

export const Shorter = ({
    val,
    len = 8,
    center = false,
    showClipIcon = true,
    isLoanTest = false,
    disableNewTab = false,
    disableLink = false,
    disableCopy = false,
    color = "black",
}) => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
            }}
        >
            <span
                className="fw-normal"
                style={{ color: color }}
                onClick={() => {
                    if (showClipIcon && val && !disableLink) {
                        if (!disableNewTab) {
                            const valType = val.length > 42 ? "tx" : "address";

                            const isTest = isLoanTest ? "rinkeby." : "";
                            window.open(
                                `https://${isTest}etherscan.io/${valType}/${val}`
                            );
                        }
                    }
                }}
            >
                {Utils.shorten(val, center, len)}
            </span>

            {val && showClipIcon && (
                <FontAwesomeIcon
                    icon={faClipboard}
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        if (showClipIcon && val && !disableCopy) {
                            window.navigator.clipboard.writeText(val);
                            enqueueSnackbar("Copied to clipboard.", {
                                variant: "success",
                            });
                        }
                    }}
                />
            )}
        </div>
    );
};

export const LoanTable = ({
    rows = [],
    total,
    page = 1,
    limit = 10,
    onChangePage,
    onDetails,
    onEdit,
    onRemove,
    onChangeOrderBy
}) => {
    const [orderBy, setOrderBy] = React.useState(
        "cast(loan.contract_loan_id as unsigned)"
    );
    const [orderByDir, setOrderByDir] = React.useState("ASC");

    const LoanRow = ({
        id,
        contract_loan_id,
        index,
        token_id,
        asset_contract_address,
        nft_price_usd,
        nft_price_eth,
        loan_amount_usd,
        loan_amount_eth,
        interest_rate,
        ltv,
        loan_duration,
        payoff_date,
        next_pay_date,
        monthly_installments_usd,
        monthly_installments_eth,
        buy_trx_hash,
        user_wallet_address,
        agent_wallet_address,
        buyer_agent_wallet_address,
        net_mode,
        market_place,
        repayToken,
        pay_method,
        status,
        user,
        nft,
        created_at,
        updated_at,
        onDetails,
        onEdit,
        onRemove,
        page,
        limit,
        loan,
    }) => {
        console.log("loan: ", { loan });
        const onView = () => {
            if (onDetails) {
                onDetails();
            }
        };

        const rowNo = (page - 1) * limit + index + 1;
        return (
            <tr>
                <td>
                    <input
                        type="checkbox"
                        className="chk_loan_item"
                        id={"chk_loan_" + id}
                    />
                </td>
                <td title={id}>
                    <Card.Link as={Link} className="fw-normal">
                        {rowNo}
                    </Card.Link>
                </td>
                <td>{contract_loan_id}</td>
                <td>
                    <div onClick={onView} style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faEye} className="me-2" />
                    </div>
                </td>
                <td>
                    <span className="fw-normal">
                        {user ? user.username : "Unknown"}
                    </span>
                </td>
                <td>
                    <Badge bg={BadgeClass[status]} className="badge-lg">
                        {("" + status).toLowerCase() == "avoid_zero"
                            ? "No Received"
                            : status}
                    </Badge>
                </td>
                <td>
                    <Shorter val={token_id} disableNewTab={true} />
                </td>
                <td>
                    <Shorter
                        val={asset_contract_address}
                        center={true}
                        len={12}
                        isLoanTest={net_mode == "test"}
                    />
                </td>
                <td>
                    <span className="fw-normal">
                        {nft ? nft.tokenName : ""}
                    </span>
                </td>
                {/* <td>
          <span className="fw-normal">{nft_price_usd}</span>
        </td> */}
                <td>
                    <span className="fw-normal">
                        {new BigNumber(nft_price_eth).toPrecision(4).toString()}{" "}
                        ETH
                    </span>
                </td>
                {/* <td>
          <span className="fw-normal">{loan_amount_usd}</span>
        </td> */}
                <td>
                    <span className="fw-normal">
                        {new BigNumber(loan_amount_eth)
                            .dividedBy(10 ** 18)
                            .toPrecision(5)
                            .toString()}{" "}
                        ETH
                    </span>
                </td>
                <td>
                    <span className="fw-normal">{interest_rate}</span>
                </td>
                <td>
                    <span className="fw-normal">{ltv}</span>
                </td>
                <td>
                    <span className="fw-normal">{loan_duration}</span>
                </td>
                <td>
                    <span className="fw-normal">
                        {payoff_date && parseInt(payoff_date) > 0
                            ? moment(parseInt(payoff_date + "")).format(
                                  "MM/DD/yyyy"
                              )
                            : "None"}
                    </span>
                </td>
                <td>
                    <span className="fw-normal">
                        {next_pay_date && parseInt(next_pay_date) > 0
                            ? moment(parseInt(next_pay_date + "")).format(
                                  "MM/DD/yyyy"
                              )
                            : "None"}
                    </span>
                </td>
                {/* <td>
          <span className="fw-normal">{monthly_installments_usd}</span>
        </td> */}
                <td>
                    <span className="fw-normal">
                        {monthly_installments_eth &&
                        monthly_installments_eth === 0
                            ? new BigNumber(monthly_installments_eth)
                                  .dividedBy(10 ** 18)
                                  .toFixed(9)
                            : ""}
                    </span>
                </td>
                <td>
                    <Shorter
                        val={buy_trx_hash}
                        center={true}
                        len={12}
                        isLoanTest={net_mode == "test"}
                    />
                </td>
                <td>
                    <Shorter
                        val={user_wallet_address}
                        center={true}
                        len={12}
                        isLoanTest={net_mode == "test"}
                    />
                </td>
                <td>
                    <Shorter
                        val={agent_wallet_address}
                        center={true}
                        len={12}
                        isLoanTest={net_mode == "test"}
                    />
                </td>
                <td>
                    <Shorter
                        val={buyer_agent_wallet_address}
                        center={true}
                        len={12}
                        isLoanTest={net_mode == "test"}
                    />
                </td>
                <td>
                    <span className="fw-normal">{market_place}</span>
                </td>
                <td>
                    <span className="fw-normal">{repayToken}</span>
                </td>
                <td>
                    <span className="fw-normal">{pay_method}</span>
                </td>
                <td>
                    <span className="fw-normal">{net_mode}</span>
                </td>
                <td>
                    <span className="fw-normal">
                        {moment(created_at).format("YYYY-MM-DDTHH:mm:ss")}
                    </span>
                </td>
                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                            as={Button}
                            split
                            variant="link"
                            className="text-dark m-0 p-0"
                        >
                            <span className="icon icon-sm">
                                <FontAwesomeIcon
                                    icon={faEllipsisH}
                                    className="icon-dark"
                                />
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    if (onRemove) onRemove();
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="me-2"
                                />
                                Remove
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
        );
    };

    const totalPages = Math.ceil(total / limit);
    const numberOfRows = rows ? rows.length : 0;

    const onAllCheck = (e) => {
        const checkboxes = document.getElementsByClassName("chk_loan_item");
        for (let one of checkboxes) {
            one.checked = e.target.checked;
        }
    };

    const onOrderBy = (orderByField) => {
      setOrderBy(orderByField);
      let newOrderDir = orderByDir == "ASC" ? "DESC" : "ASC";
      setOrderByDir(newOrderDir);
      onChangeOrderBy(orderByField, newOrderDir);
    };

    const OrderByTag = ({ defaultOrderBy, orderByField, orderByFieldDir }) => {
        if (defaultOrderBy != orderByField) {
            return null;
        }

        return (
            <div style={{ display: "inline", paddingLeft: 5 }}>
                <FontAwesomeIcon
                    icon={orderByFieldDir == "ASC" ? faArrowDown : faArrowUp}
                />
            </div>
        );
    };

    return (
        <Card
            border="light"
            className="table-wrapper table-responsive shadow-sm"
        >
            <Card.Body className="pt-0">
                <Table hover className="user-table align-items-center">
                    <thead>
                        <tr>
                            <th className="border-bottom">
                                <input
                                    type="checkbox"
                                    onChange={onAllCheck}
                                ></input>
                            </th>
                            <th className="border-bottom">Sr.No</th>
                            <th
                                className="border-bottom"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    onOrderBy(
                                        "cast(loan.contract_loan_id as unsigned)"
                                    )
                                }
                            >
                                Loan ID
                                <OrderByTag
                                    defaultOrderBy={
                                        "cast(loan.contract_loan_id as unsigned)"
                                    }
                                    orderByField={orderBy}
                                    orderByFieldDir={orderByDir}
                                />
                            </th>
                            <th className="border-bottom">Details</th>
                            <th
                                className="border-bottom"
                                style={{ cursor: "pointer" }}
                                onClick={() => onOrderBy("user.username")}
                            >
                                User Name
                                <OrderByTag
                                    defaultOrderBy={"user.username"}
                                    orderByField={orderBy}
                                    orderByFieldDir={orderByDir}
                                />
                            </th>
                            <th
                                className="border-bottom"
                                style={{ cursor: "pointer" }}
                                onClick={() => onOrderBy("loan.status")}
                            >
                                Status
                                <OrderByTag
                                    defaultOrderBy={"loan.status"}
                                    orderByField={orderBy}
                                    orderByFieldDir={orderByDir}
                                />
                            </th>
                            <th className="border-bottom">Token Id</th>
                            <th className="border-bottom">Token Address</th>
                            <th className="border-bottom">Token Name</th>
                            {/* <th className="border-bottom">NFT Price(USD)</th> */}
                            <th
                                className="border-bottom"
                                style={{ cursor: "pointer" }}
                                onClick={() => onOrderBy("loan.nft_price_eth")}
                            >
                                NFT Price(ETH)
                                <OrderByTag
                                    defaultOrderBy={"loan.nft_price_eth"}
                                    orderByField={orderBy}
                                    orderByFieldDir={orderByDir}
                                />
                            </th>
                            {/* <th className="border-bottom">Loan Amount(USD)</th> */}
                            <th className="border-bottom">Loan Amount(ETH)</th>
                            <th className="border-bottom">Interest Rate</th>
                            <th className="border-bottom">LTV</th>
                            <th className="border-bottom">Loan Duration</th>
                            <th className="border-bottom">PayOff At</th>
                            <th className="border-bottom">Next Pay At</th>
                            {/* <th className="border-bottom">MI (USD)</th> */}
                            <th className="border-bottom">MI (ETH)</th>
                            <th className="border-bottom">Agent NFT Buy Trx</th>
                            <th className="border-bottom">User Wallet</th>
                            <th className="border-bottom">Agent Wallet</th>
                            <th className="border-bottom">Buyer(Wallet)</th>

                            <th className="border-bottom">Market Place</th>
                            <th className="border-bottom">Repay Token</th>
                            <th className="border-bottom">Pay Method</th>
                            <th className="border-bottom">Net Mode</th>
                            <th className="border-bottom">Created At</th>
                            <th className="border-bottom">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((loan, index) => {
                            if (!loan) {
                                return null;
                            }
                            return (
                                <LoanRow
                                    index={index}
                                    key={`user-${loan.id}`}
                                    {...loan}
                                    loan={loan}
                                    nft={loan.nft}
                                    onDetails={() => onDetails(loan)}
                                    onRemove={() => onRemove(loan)}
                                    onEdit={() => onEdit(loan)}
                                    page={page}
                                    limit={limit}
                                />
                            );
                        })}
                    </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                    <Nav>
                        <Pagination className="mb-2 mb-lg-0">
                            <Pagination.First
                                onClick={() => {
                                    onChangePage(1);
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                            </Pagination.First>
                            <Pagination.Prev
                                onClick={() => {
                                    onChangePage(page - 1 > 0 ? page - 1 : 1);
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </Pagination.Prev>
                            <Pagination.Item>{page}</Pagination.Item>
                            <Pagination.Next
                                onClick={() => {
                                    onChangePage(
                                        page + 1 <= totalPages
                                            ? page + 1
                                            : totalPages
                                    );
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleRight} />
                            </Pagination.Next>
                            <Pagination.Last
                                onClick={() => {
                                    onChangePage(totalPages);
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleDoubleRight} />
                            </Pagination.Last>
                        </Pagination>
                    </Nav>
                    <small className="fw-bold">
                        Showing <b>{numberOfRows}</b> out of <b>{total}</b>{" "}
                        entries
                    </small>
                </Card.Footer>
            </Card.Body>
        </Card>
    );
};
