import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Api from "../../apis/Api";
import { useSnackbar } from 'notistack';
import { Routes } from "../../routes";

export const UserInfoForm = ({}) => {
  const selUser = useSelector((state) => state.root.selUser);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory()
  const [username, setUsername] = React.useState(
    selUser ? selUser.username : ""
  );
  const [email, setEmail] = React.useState(selUser ? selUser.email : "");
  const [role, setRole] = React.useState(selUser ? selUser.role : "");
  const [code, setCode] = React.useState(selUser ? selUser.code : "");
  const [isActive, setIsActive] = React.useState(
    selUser ? selUser.isActive ? 1 : 0 : 0
  );


  const handleCancel = ()=>{
    history.go(-1)
  }

  const handleSave = async(e)=>{
    e.preventDefault();
    const data = {
      id: selUser.id, email, role, isActive, username
    }
    try
    {
      const res = await Api.updateUser(selUser.id, data)
      
      enqueueSnackbar('Successfully Updated', {
        variant:'success'
      })
      history.replace(Routes.Users.path)

    }catch(ex){
      enqueueSnackbar(ex.message, {
        variant:'error'
      })
    }
    
  }

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">User information</h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>User Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  placeholder="Enter your first name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="name@company.com"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="role">
                <Form.Label>Role</Form.Label>
                <Form.Select
                  defaultValue="0"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  type="text"
                  disabled
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  placeholder="User Code"
                />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="role">
                <Form.Label>Email Activate</Form.Label>
                <Form.Select
                  defaultValue={isActive}
                  value={isActive}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setIsActive(e.target.value);
                  }}
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3">
            <Row>              
              <Col sm={12} className="mb-3">
                
                  <Button variant="danger" type="button" onClick={handleCancel}>
                    Cancel
                  </Button>                  
                  <Button variant="primary" type="button" className="mx-2" onClick={handleSave}>
                    Save
                  </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
