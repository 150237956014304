import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Card,
  Button,
  Table,
  Dropdown,
  Pagination,
  ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Shorter } from "../loans/LoanTable";

export function PaginationFooter({
  page,
  onChangePage,
  totalPages,
  numberOfRows,
  total,
}) {
  return (
    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
      <Nav>
        <Pagination className="mb-2 mb-lg-0">
          <Pagination.First
            onClick={() => {
              onChangePage(1);
            }}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Pagination.First>
          <Pagination.Prev
            onClick={() => {
              onChangePage(page - 1 > 0 ? page - 1 : 1);
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Pagination.Prev>
          <Pagination.Item>{page}</Pagination.Item>
          <Pagination.Next
            onClick={() => {
              onChangePage(page + 1 <= totalPages ? page + 1 : totalPages);
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Pagination.Next>
          <Pagination.Last
            onClick={() => {
              onChangePage(totalPages);
            }}
          >
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </Pagination.Last>
        </Pagination>
      </Nav>
      <small className="fw-bold">
        Showing <b>{numberOfRows}</b> out of <b>{total}</b> entries
      </small>
    </Card.Footer>
  );
}

export const FavTable = ({
  rows = [],
  total,
  page = 1,
  limit = 10,
  onChangePage,
  onDetails,
  onEdit,
  onRemove,
}) => {
  const TableRow = ({
    id,
    token_id,
    tokenName,
    tokenDesc,
    tokenImgUrl,
    asset_contract_address,
    asset_contract_type,
    asset_logo,
    ownerName,
    sourceName,
    tokenUSDPrice,
    tokenPrice,
    tokenPriceImage,
    tokenUnit,
    created_at,
    updated_at,
    user,
    onDetails,
    onEdit,
    onRemove,
    index,
    page,
    limit,
  }) => {
    const onView = () => {
      if (onDetails) {
        onDetails();
      }
    };
    const rowNo = (page-1) * limit + index + 1;
    return (
      <tr>
        <td>
          <Card.Link as={Link} className="fw-normal">
            {rowNo}
          </Card.Link>
        </td>
        <td>
          <img src={tokenImgUrl} style={{ width: 50, height: 50 }} />
        </td>
        <td>
          <Card.Link as={Link} className="fw-normal">
            {user.email}
          </Card.Link>
        </td>
        <td>
          <Shorter val={token_id} />
        </td>
        <td>
          <Shorter val={asset_contract_address} center={true} len={12} />
        </td>
        <td>
          <span className="fw-normal">{tokenName}</span>
        </td>
        <td>
          <span className="fw-normal">
            <Shorter val={tokenDesc} len={20} />
          </span>
        </td>

        <td>
          <span className="fw-normal">{asset_contract_type}</span>
        </td>
        <td>
          <span className="fw-normal">{tokenUSDPrice}</span>
        </td>
        <td>
          <span className="fw-normal">
            {tokenPrice} {tokenUnit}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {moment(created_at).format("MM/DD/YYYY")}
          </span>
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                className="text-danger"
                onClick={() => {
                  onRemove(id);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  const totalPages = Math.ceil(total / limit);
  const numberOfRows = rows ? rows.length : 0;

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Sr.No</th>
              <th className="border-bottom">Media</th>
              <th className="border-bottom">User</th>
              <th className="border-bottom">Token ID</th>
              <th className="border-bottom">Token Address</th>
              <th className="border-bottom">Token Name</th>
              <th className="border-bottom">Token Desc</th>
              <th className="border-bottom">Contract Type</th>
              <th className="border-bottom">Price (USD)</th>
              <th className="border-bottom">Price</th>
              <th className="border-bottom">Created At</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((fav, index) => (
              <TableRow
                key={`fav-${fav.id}`}
                {...fav}
                index={index}
                page={page}
                limit={limit}
                // rowIndex={index + 1}
                onDetails={() => onDetails(fav)}
                onRemove={() => onRemove(fav)}
                onEdit={() => onEdit(fav)}
              />
            ))}
          </tbody>
        </Table>
        <PaginationFooter
          page={page}
          onChangePage={onChangePage}
          totalPages={totalPages}
          numberOfRows={numberOfRows}
          total={total}
        />
      </Card.Body>
    </Card>
  );
};
