import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../apis/Api";
import { useHistory } from "react-router";
import { DropDownLimitReloader } from "../favorites";
import { TransactionsTable } from "../../components/Tables";
import { useAuth } from "../../context/AuthContext";

const SearchFieldsTransaction = [
  { label: "ALL", slug: null },
  { label: "User", slug: "user_id" },
  
  { label: "Trx Type", slug: "trx_type" },
  { label: "From", slug: "from" },
  { label: "To", slug: "to" },
  { label: "PayIndex", slug: "index_repayment" },
  { label: "TRX HASH", slug: "trx_hash" },
  { label: "NFT TOKENID", slug: "nft_token_id" },
  { label: "NFT ASSET ADDR", slug: "nft_token_address" },
  { label: "MARKET", slug: "market_place" },
  { label: "NET MODE", slug: "net_mode" },
];

export default function Transactions() {
  const { token } = useAuth();

  const [page, setPage] = React.useState(1);
  const limit = useSelector((state) => state.root.limit);
  const [loading, setLoading] = React.useState(false);

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [searchField, setSearchField] = React.useState();
  const [search, setSearch] = React.useState();

  React.useEffect(() => {
    loadData();
  }, [page, limit]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res = await Api.transList({ page, limit, token, search, searchField });
      setLoading(false);
      if (res.data && res.data.status == 200) {
        setRows(res.data.data);
        setTotalRows(res.data.total);
      } else {
        setRows([]);
        alert("Error while loading : " + res.error);
      }
    } catch (ex) {
      console.log(ex);
      setLoading(false);

      alert(ex.message);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Transactions</h4>
          {/* <p className="mb-0">Your web analytics dashboard template.</p> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/* <ButtonGroup>
            <Button variant="outline-primary" size="sm">
              Share
            </Button>
            <Button variant="outline-primary" size="sm">
              Export
            </Button>
          </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="align-items-center">
          <Col xs={5} md={3} lg={4} xl={3}>

            <InputGroup>
              <Form.Control
                type="text" placeholder="Search"
                value={search}
                onChange={e => { setSearch(e.target.value) }}
              />
              <Button variant="outline-primary" id="button-addon2" onClick={loadData}>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>

          </Col>
          <Col xs={3} md={3} lg={4} xl={3}>
            <Form.Group className="w-100">
              <Form.Select
                value={searchField}
                onChange={(e) => {
                  setSearchField(e.target.value);
                }}
              >
                {SearchFieldsTransaction.map((field) => {
                  return <option value={field.slug} key={field.slug}>{field.label}</option>;
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col
            xs={{ span: 4, offset: 0 }}
            md={{ span: 3, offset: 3 }}
            lg={{ span: 3, offset: 1 }}
            xl={{ span: 2, offset: 4 }}
            className="ps-md-0 text-end"
          >
            <DropDownLimitReloader loading={loading} loadData={loadData} />
          </Col>
        </Row>
      </div>

      <TransactionsTable
        rows={rows}
        total={totalRows}
        page={page}
        limit={limit}
        onChangePage={(newPage) => {
          setPage(newPage);
        }}
      />
    </>
  );
}
