import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../apis/Api";
import { useHistory } from "react-router";
import { DropDownLimitReloader } from "../favorites";
import { LogsTable, TransactionsTable } from "../../components/Tables";
import { useAuth } from "../../context/AuthContext";

export default function Logs() {

  const { token } = useAuth();

  const [page, setPage] = React.useState(1);
  const limit = useSelector(state=>state.root.limit);
  const [loading, setLoading] = React.useState(false);

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);


  React.useEffect(() => {
    loadData();
  }, [page, limit]);

  const loadData = async () => {
    try {

      setLoading(true);
      const res = await Api.getLogFiles();
      setLoading(false);

      console.log(res)
      
      if (res?.data && res?.status == 200) {
        setRows(res.data);
        setTotalRows(res.data?.length);
      } else {
        setRows([]);
        alert("Error while loading : " + res.status + ', ' + res.data.status);
      }

    } catch (ex) {
      setLoading(false);

      alert(ex.message);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Logs</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Logs</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <DropDownLimitReloader
              loading={loading}
              loadData={loadData}
            />
          </Col>
        </Row>
      </div>

      <LogsTable
        rows={rows}
        total={totalRows}
        page={page}
        limit={limit}
        onChangePage={(newPage)=>{
          setPage(newPage)
        }}
      />
    </>
  );
}
