import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faSearch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
    Col,
    Row,
    Form,
    Button,
    Breadcrumb,
    InputGroup,
    Modal,
} from "@themesberg/react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../apis/Api";
import { DropDownLimitReloader } from "../favorites";
import { useAuth } from "../../context/AuthContext";
import { EventsTable } from "./events_table";

const SearchFieldsTransaction = [
    { label: "ALL", slug: null },
    { label: "LastBlockNumber", slug: "lastBlockNumber" },
    { label: "BlockHash", slug: "blockHash" },
    { label: "TransactionHash", slug: "lastTransactionHash" },
    { label: "Event Name", slug: "event_name" },
    { label: "Network", slug: "network" },
    { label: "Created At", slug: "created_at" },
];

export default function Events() {
    const { token } = useAuth();

    const [page, setPage] = React.useState(1);
    const limit = useSelector((state) => state.root.limit);
    const [loading, setLoading] = React.useState(false);

    const [rows, setRows] = React.useState([]);
    const [totalRows, setTotalRows] = React.useState(0);
    const [searchField, setSearchField] = React.useState();
    const [search, setSearch] = React.useState();
    const [isRestoring, setIsRestoring] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [restoreResult, setRestoreResult] = React.useState("");

    React.useEffect(() => {
        loadData();
    }, [page, limit]);

    const loadData = async () => {
        try {
            setLoading(true);
            const res = await Api.getEvents({
                page,
                limit,
                token,
                search,
                searchField,
            });
            setLoading(false);
            if (res.data && res.data.status == 200) {
                setRows(res.data.data);
                setTotalRows(res.data.total);
            } else {
                setRows([]);
                alert("Error while loading : " + res.error);
            }
        } catch (ex) {
            console.log(ex);
            setLoading(false);

            alert(ex.message);
        }
    };

    const onChkChange = (e) => {
        console.log({ checked: e.target.checked });
    };

    const onClickRemoveSelected = async () => {
        let checkboxes = document.getElementsByClassName("chk_event_item");
        let ids = [];
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                ids.push(checkboxes[i].id.replace("chk_event_", ""));
            }
        }
        console.log("Selected events ids : ", ids);

        if (
            window.confirm(
                "Are you sure to remove selected events? Please confirm if you selected latest event. it can not be removed."
            )
        ) {
            setLoading(true);
            const res = await Api.removeEvents({
                ids,
                token,
            });
            console.log("remove result: ", res);
            setLoading(false);
            loadData();
        }
    };

    const handleRestoreEvent = async (blockNumber, txHash) => {
        if (isRestoring) {
            return;
        }
        setIsRestoring(true);
        setShowModal(true);
        try {
            const res = await Api.restoreLoanFromEvent(blockNumber, txHash);
            console.log("Restore result: ", { res });
            setRestoreResult(JSON.stringify(res?.data, null, 2));
            // alert("Restore result: " + JSON.stringify(res));
        } catch (ex) {
            console.log({ ex });
            alert("Failed to restore. " + ex.message);
        }

        setIsRestoring(false);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item active>Events</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Events</h4>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0"></div>
            </div>

            <div className="table-settings mb-4">
                <Row className="align-items-center">
                    <Col xs={5} md={3} lg={4} xl={3}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                }}
                            />
                            <Button
                                variant="outline-primary"
                                id="button-addon2"
                                onClick={loadData}
                            >
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col xs={3} md={3} lg={4} xl={3}>
                        <Form.Group className="w-100">
                            <Form.Select
                                value={searchField}
                                onChange={(e) => {
                                    setSearchField(e.target.value);
                                }}
                            >
                                {SearchFieldsTransaction.map((field) => {
                                    return (
                                        <option
                                            value={field.slug}
                                            key={field.slug}
                                        >
                                            {field.label}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col
                        xs={{ span: 4, offset: 0 }}
                        md={{ span: 3, offset: 3 }}
                        lg={{ span: 3, offset: 1 }}
                        xl={{ span: 2, offset: 4 }}
                        className="ps-md-0 text-end"
                    >
                        <DropDownLimitReloader
                            loading={loading}
                            loadData={loadData}
                        />
                    </Col>
                </Row>
            </div>

            <EventsTable
                rows={rows}
                total={totalRows}
                page={page}
                limit={limit}
                isRestoring={isRestoring}
                onChangePage={(newPage) => {
                    setPage(newPage);
                }}
                onChkChange={(e) => {
                    onChkChange(e);
                }}
                onRestore={handleRestoreEvent}
            />
            <div className="row mt-1">
                <div className="col text-right">
                    <Button
                        variant="danger"
                        className="m-1"
                        onClick={onClickRemoveSelected}
                    >
                        Remove Selected Events
                    </Button>
                </div>
            </div>

            <Modal
                as={Modal.Dialog}
                centered
                show={showModal}
                onHide={()=>{}}
            >
                <Modal.Header>
                    <Modal.Title className="h6">
                        Restoring loan from Event...
                    </Modal.Title>
                    {/* <Button
                        variant="close"
                        aria-label="Close"
                        onClick={handleClose}
                    /> */}
                </Modal.Header>
                <Modal.Body>
                    {isRestoring ? (
                        <>
                            <div
                                class="spinner-border text-danger"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>{" "}
                            <p> Please wait a moment....</p>
                        </>
                    ) : (
                        <p>{restoreResult}</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!isRestoring && (
                        <Button
                            variant="link"
                            className="text-gray ms-auto"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}
