import React, { useState } from "react";
import { Col, Row, Button, Form, Card } from "@themesberg/react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import { useHistory } from "react-router";
import { Routes } from "../../routes";
import Api from "../../apis/Api";
import { useSnackbar } from "notistack";
import Code from "../../components/Code";
import MailApi from "../../apis/mails/index";

import AccordianComponent from "../../components/AccordionComponent";

const Param = ({ name, type, onChange, className, value = "" }) => {
  return (
    <div className={"" + className}>
      <div>
        {name}:[{type}]
      </div>
      <input
        type={"text"}
        className="w-100"
        onChange={(e) => {
          onChange({ name, type, value: e.target.value });
        }}
        value={value}
      ></input>
    </div>
  );
};

const MailParamFields = ({ mailType, onSend }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [params, setParams] = useState({});
  const _onSend = () => {
    console.log('_onSend ', {
      mailType,
      params,
    });
    
    if (!params || Object.keys(params).length == 0 || Object.keys(params).length != mailType.params.length) {
      enqueueSnackbar("Invalid params", { variant: "error" });
      return;
    }

    onSend(mailType, params);
  };

  const _onClear = ()=>{
    setParams({})
  }

  const onChangeParam = (name, type, value) => {
    const _params = { ...params };

    if (type == "array") {
      _params[name] = value.split(",");
    } else {
      _params[name] = value;
    }

    setParams(_params);
  };

  return (
    <div className="row pt-2">
      <div className="row pt-1">
        {mailType.params?.map((param, index) => {
          return (
            <Param
              key={"mpf_" + index}
              className="col-md-3 pt-1"
              value={params[param.name]}
              {...param}
              onChange={({ name, type, value }) => {
                onChangeParam(name, type, value);
              }}
            />
          );
        })}
      </div>
      <Row className="mt-2">
        <Col>
          <Button size="sm" onClick={_onSend}>
            Send
          </Button>
          <Button variant="danger" className="ms-2" size="sm" onClick={_onClear}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default function () {
  const [mailTypes, setMailTypes] = React.useState([]);
  const [receiver, setReceiver] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const loadMailType = async () => {
    try {
      const res = await MailApi.getMailTypes();
      if (res.data) {
        setMailTypes(res.data);
      }

      console.log("Mail types::", res);
    } catch (ex) {
      console.log("ex:", ex);
      enqueueSnackbar("Failed to get mail types from server.", {
        variant: "error",
      });
    }
  };

  const onSend = async (mailType, params) => {
    if (!receiver) {
      enqueueSnackbar(
        "Invalid receiver email, please input correct receiver email.",
        { variant: "error" }
      );
      return;
    }
    try {
      const res = await MailApi.sendTestEmail(mailType.type, receiver, params);
      console.log("mail result: ", res);
    } catch (ex) {
      console.log("Exception: ", ex);
    }
  };

  React.useEffect(() => {
    loadMailType();
    return () => {};
  }, []);

  return (
    <Row>
      <Col xs={12} xl={12}>
        <Card>
          <Card.Body>
            <Row className="mt-2 mb-2">
              <Col>
                <span>Receiver Email: </span>
                <input
                  type="email"
                  value={receiver}
                  onChange={(e) => {
                    setReceiver(e.target.value);
                  }}
                ></input>
              </Col>
            </Row>
            <AccordianComponent
              defaultKey="acc_mail"
              data={mailTypes.map((mailType, index) => {
                return {
                  id: index,
                  title: mailType.type,
                  description: (
                    <MailParamFields mailType={mailType} onSend={onSend} />
                  ),
                  eventKey: "event_" + index,
                };
              })}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
